import { Controller } from '@hotwired/stimulus'
import { get } from 'lodash-es'

export default class extends Controller {
  static values = {
    url: String
  }

  static targets = ['frame']

  onChange(e) {
    e.preventDefault()
    if (!this.hasFrameTarget) {
      console.error('unable to refresh dataset fields')
      return false
    }

    const regex = /.*\[(?<name>[a-z_]+)\]/
    let params = new URLSearchParams()
    let name, value, matches
    this.element.querySelectorAll('.tile-dataset-input').forEach((elem) => {
      if (elem.type == 'checkbox' && elem.checked == false) {
        return
      } else {
        matches = elem.getAttribute('name').match(regex)
        name = get(matches, 'groups.name')
        value = elem.value
  
        params.set(name, value)
      }
    })

    this.frameTarget.setAttribute('src', this.urlValue + "?" + params)
  }
}
