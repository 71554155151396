import { Controller } from '@hotwired/stimulus';
import { EventBus } from '../../../lib/event_bus';

export default class extends Controller {
  static values = { numActions: Number };
  static targets = ['confirm', 'notes', 'raisedLabel', 'unraisedLabel', 'submit'];

  handleConfirmToggled = this.handleConfirmToggled.bind(this);
  handleActionsChanged = this.handleActionsChanged.bind(this);

  connect() {
    EventBus.on('action-presence:added', () => {
      this.handleActionsChanged(1);
    });
    EventBus.on('action-presence:removed', () => {
      this.handleActionsChanged(-1);
    });
    if (this.hasConfirmTarget) {
      this.confirmTarget.addEventListener('change', this.handleConfirmToggled);
    }
  }

  disconnect() {
    if (this.hasConfirmTarget) {
      this.confirmTarget.removeEventListener('change', this.handleConfirmToggled);
    }
  }

  handleConfirmToggled() {
    if (this.confirmTarget.checked && this.numActionsValue <= 0) {
      this.notesTarget.classList.remove('hidden');
      this.notesTarget.querySelector('textarea').disabled = false;
    } else {
      this.notesTarget.classList.add('hidden');
      this.notesTarget.querySelector('textarea').disabled = true;

    }

    this.submitTarget.disabled = !this.confirmTarget.checked;
  }

  handleActionsChanged(delta) {
    this.numActionsValue += delta;

    if (this.numActionsValue <= 0) {
      this.raisedLabelTarget.classList.add('hidden');
      this.unraisedLabelTarget.classList.remove('hidden');
    } else {
      this.raisedLabelTarget.classList.remove('hidden');
      this.unraisedLabelTarget.classList.add('hidden');
    }
    this.confirmTarget.checked = false;
    this.handleConfirmToggled();
  }
}
