import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["preview", "spinner"];

  connect() {
    document.addEventListener("DOMContentLoaded", this.previewLoaded.bind(this));
  }

  previewLoaded() {
    this.spinnerTarget.classList.add('hide');
    this.previewTargets.forEach((preview) => {
      preview.classList.remove('hide');
    })
  }
}
