import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ['contractSelect']

  connect() {
    const radio = this.element.querySelector('input[type=radio]:checked')
    this.highlightChoice(radio)
  }

  modeSelected(e) {
    this.element.querySelectorAll('label').forEach((elem) => elem.classList.remove('font-weight-bold'))
    const radio = e.target
    this.highlightChoice(radio)

  }

  highlightChoice(radio) {
    if (radio) {
      radio.closest('label').classList.add('font-weight-bold')

      if (radio.value == 'contract') {
        this.contractSelectTarget.disabled = false
      } else {
        this.contractSelectTarget.disabled = true
      }
    }
  }
}