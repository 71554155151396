import { Controller } from "@hotwired/stimulus"
import { escapeRegExp, get } from "lodash-es"

export default class extends Controller {
  static targets = [
    "jobForm",
    "riskInput",
    "statusInput",
    "ownerInput",
    "findUserInput"
  ]

  findUser(event) {
    const regex = new RegExp(".*\\b" + escapeRegExp(this.findUserInputTarget.value) + ".*", "gi")
    let userOptions = this.element.querySelectorAll(".user-option")
    let matchCount = 0
    if (this.findUserInputTarget.value.length > 0) {
      userOptions.forEach(function (elem, index) {
        if (elem.textContent.match(regex)) {
          elem.classList.remove("hide")
          matchCount += 1
        } else {
          elem.classList.add("hide")
        }
      })
    }
    if (matchCount == 0) {
      userOptions.forEach(function (elem, index) {
        elem.classList.add("hide")
      })
    }
  }

  setRisk(event) {
    let riskId = event.currentTarget.dataset.risk
    if (this.riskInputTarget.value == riskId) {
      event.preventDefault()
      return false
    }
    this.riskInputTarget.value = riskId
    this.jobFormTarget.submit()
  }

  setStatus(event) {
    let statusId = event.currentTarget.dataset.status
    if (this.statusInputTarget.value == statusId) {
      event.preventDefault()
      return false
    }

    this.statusInputTarget.value = statusId
    this.jobFormTarget.submit()
  }

  setOwner(event) {
    let ownerId = event.currentTarget.dataset.owner
    let assignPath = event.currentTarget.dataset.path
    this.ownerInputTarget.value = ownerId
    this.jobFormTarget.action = assignPath
    this.jobFormTarget.submit()
  }
}
