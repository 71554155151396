import { Controller } from '@hotwired/stimulus'
import { get, join } from 'lodash-es'

export default class extends Controller {

  static targets = ['entry']
  static values = {
    url: String
  }

  onSelect(event) {
    event.preventDefault()

    const targetEntry = event.currentTarget
    const priority = get(targetEntry, 'dataset.priority')
    const grade = get(targetEntry, 'dataset.grade')

    const selectGroup = !(grade && priority) // either a row or column-level selector
    let selectedValues
    if (selectGroup) {
      selectedValues = this.selectGroup(grade, priority)
    } else {
      selectedValues = [`${grade}${priority}`]
    }

    this.navigateTo(selectedValues)
  }

  selectGroup(grade, priority) {
    if (grade) {
      return [`${grade}1`, `${grade}2`, `${grade}3`, `${grade}4`]
    } else {
      return [`A${priority}`, `B${priority}`, `C${priority}`, `D${priority}`]
    }
  }

  navigateTo(values) {
    let url = new URL(location.origin + this.urlValue)
    let params = url.searchParams
    params.set('grade_priorities', join(values))
    location.href = url.toString()
  }

}