import { Controller } from "@hotwired/stimulus"
import { get } from "lodash-es";

export default class extends Controller {

  static values = {
    dailyFrequency: Boolean
  }

  static targets = [
    'scheduleOption'
  ];

  setDailyFrequency(e) {
    const selectElem = e.currentTarget
    this.dailyFrequencyValue = get(selectElem, 'selectedOptions[0].textContent', '') == 'Daily'
  }

  dailyFrequencyValueChanged() {
    this.scheduleOptionTargets.forEach(elem => {
      const schedule = get(elem, 'dataset.schedule')
      if (schedule == 'earlier') {
        if (this.dailyFrequencyValue) {
          elem.classList.add('hide')
          elem.querySelector('input[type=radio]').setAttribute('disabled', true)
        } else {
          elem.classList.remove('hide')
          elem.querySelector('input[type=radio]').removeAttribute('disabled')
        }
      } else {
        if (this.dailyFrequencyValue) {
          this.hideAll(elem.querySelectorAll('span[data-schedule="other"]'))
          this.showAll(elem.querySelectorAll('span[data-schedule="daily"]'))
        } else {
          this.showAll(elem.querySelectorAll('span[data-schedule="other"]'))
          this.hideAll(elem.querySelectorAll('span[data-schedule="daily"]'))
        }
      }
    });
  }

  hideAll(elements) {
    elements.forEach(elem => { elem.classList.add('hide') })
  }

  showAll(elements) {
    elements.forEach(elem => { elem.classList.remove('hide') })
  }
}
