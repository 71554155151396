import { filter } from 'lodash-es'
import { CheckboxSelectAllController } from 'stimulus-library'

export default class extends CheckboxSelectAllController {

  static targets = ['total']

  updateTotal() {
    const totalChecked = filter(this.checkboxTargets, { checked: true }).length
    this.totalTarget.textContent = totalChecked
  }

  _toggle(e) {
    super._toggle(e)

    this.updateTotal()
  }

}
