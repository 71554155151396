import { Controller } from '@hotwired/stimulus';

// Formats a timestamp in the markup into a human-readable relative time.
// There is scope to extend this controller to support a format option to
// make it work in more situations.
export default class extends Controller {

  static values = { datetime: String }

  connect() {
    this.formatDate()
  }

  formatDate() {
    const date = moment(this.datetimeValue)

    // Current behaviour is to show Today, Yesterday or the day of the week
    // if the date is within the last 5 days.

    if (date.isSame(moment(), 'day')) {
      this.element.innerHTML = "Today at " + date.format('H:mm')
    }
    else if (date.isSame(moment().subtract(1, 'days'), 'day')) {
      this.element.innerHTML = "Yesterday at " + date.format('H:mm')
    }
    else if (date.isAfter(moment().subtract(5, 'days'))) {
      this.element.innerHTML = date.format('dddd [at] H:mm')
    }
    else {
      if (date.year() == moment().year()) {
        this.element.innerHTML = date.format('Do MMM')
      }
      else {
        this.element.innerHTML = date.format('Do MMM YYYY')
      }
    }
  }
}
