import HiddenModalController from '../shared/hidden_modal_controller'
import { debounce } from 'lodash-es'

export default class extends HiddenModalController {

  static targets = ['keyCombo', 'search', 'results']

  connect() {
    this.handleResultSelection = debounce(this.handleResultSelection, 200).bind(this)
  }

  toggleModal(e) {
    if (!this.isQuickSearchEvent(e)) {
      return
    }
    e.preventDefault()

    super.toggleModal(e)
  }

  isQuickSearchEvent(e) {
    if (e.type == 'click') {
      return true
    }
    if (e.type == 'keydown' && e.key == 'k' && (e.metaKey || e.ctrlKey)) {
      return true
    }

    return false
  }

  keyComboTargetConnected(elem) {
    const isMac = window.navigator.userAgent.indexOf('Mac') != -1
    if (isMac) {
      elem.textContent = '⌘+K'
    } else {
      elem.textContent = 'Ctrl+K'
    }
  }

  searchTargetConnected(elem) {
    elem.addEventListener('keydown', this.handleResultSelection.bind(this))
  }

  resultsTargetConnected(elem) {
    this.focusResult(this.currentResult())
  }

  handleResultSelection(e) {
    let targetResult
    switch (e.keyCode) {
      case 13:
        e.preventDefault()
        targetResult = this.currentResult()
        if (targetResult) {
          targetResult.click()
        }
        break
      case 38:
        e.preventDefault()
        targetResult = this.previousResult()
        this.focusResult(targetResult)
        break
      case 40:
        e.preventDefault()
        targetResult = this.nextResult()
        this.focusResult(targetResult)
        break
    }
  }

  previousResult() {
    const current = this.currentResult().parentElement
    const prev = current.previousElementSibling
    if (prev) {
      return prev.querySelector('a')
    } else {
      return this.currentResult()
    }
  }

  nextResult() {
    const current = this.currentResult().parentElement
    const next = current.nextElementSibling
    if (next) {
      return next.querySelector('a')
    } else {
      return this.currentResult()
    }
  }

  currentResult() {
    let currentResult
    if (this.hasResultsTarget) {
      currentResult = this.resultsTarget.querySelector('.choice-list li a.selected')
      if (!currentResult) {
        currentResult = this.resultsTarget.querySelector('.choice-list li a')
      }
    }

    return currentResult
  }

  focusResult(targetResult) {
    this.element.querySelectorAll('.choice-list li a').forEach(elem => elem.classList.remove('selected'))
    if (targetResult) {
      targetResult.classList.add('selected')
      targetResult.parentElement.scrollIntoView({ behavior: 'auto', block: 'center', inline: 'nearest' })
    }
  }
}
