import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  static get targets() {
    return [
      'reportedToDiv', 'reportedToInput',
      'reportedOnDiv', 'reportedOnInput',
      'reportedByDiv', 'reportedByInput',
      'reportedToBlock'
    ];
  }


  connect() {
    this.update_field_visibility();
  }

  domShow(e) {
    e.classList.add('visible');
    e.classList.remove('hidden');
  }

  domHide(e) {
    e.classList.add('hidden');
    e.classList.remove('visible');
  }

  update_field_visibility() {
    if (this.reportedToInputTarget.checked) {
      this.domShow(this.reportedToBlockTarget);
    }
    else {
      this.domHide(this.reportedToBlockTarget);

      let event = new CustomEvent('datepicker:clear')
      document.getElementById('incident-report-datepicker').dispatchEvent(event)

      this.reportedByInputTarget.value = '';
    }

  }
}
