import { Controller } from '@hotwired/stimulus';

// Observe the current element for changes, attempting to parse its content as
// an integer.
//
// If the content is successfully parsed, try and update the elements selected by
// its value to their correct form.
//
// Example:
// %span{ data: { controller: 'pluralize', pluralize: { selector_value: '#that_element', singular_value: 'response', plural_value: 'responses' } }
//  2
// %span#that-element
//  responses
export default class extends Controller {
  static values = { selector: String, singular: String, plural: String }

  connect() {
    const config = {
      characterData: true,
      childList: true
    }

    this.observer = new MutationObserver((_mutationsList, observer) => {
      const value = parseInt(this.element.innerHTML)
      const elements = document.querySelectorAll(this.selectorValue)

      if (isNaN(value) || elements.length === 0) {
        return
      }

      observer.disconnect()
      elements.forEach(element => {
        if (value === 1) {
          element.innerHTML = this.singularValue
        } else if (value >= 1) {
          element.innerHTML = this.pluralValue
        }
      })
      observer.observe(this.element, config)
    })

    this.observer.observe(this.element, config)
  }

  disconnect() {
    this.observer.disconnect()
  }
}