import { Controller } from "@hotwired/stimulus"
import { get } from 'lodash-es'

export default class extends Controller {

  static targets = ['gb', 'nonGb']

  onChange(e) {
    const countryCode = get(e, 'currentTarget.value')
    if (countryCode == 'GB' || countryCode == '') {
      this.enable(this.gbTargets)
      this.disable(this.nonGbTargets)
    } else {
      this.disable(this.gbTargets)
      this.enable(this.nonGbTargets)
    }
  }

  disable(targets) {
    targets.forEach(element => {
      element.classList.add('d-none')
      element.querySelectorAll('input').forEach(elem => {
        elem.disabled = true
      })
    })
  }

  enable(targets) {
    targets.forEach(element => {
      element.classList.remove('d-none')
      element.querySelectorAll('input').forEach(elem => {
        elem.disabled = false
      })
    })
  }

}