import { Controller } from '@hotwired/stimulus'
import { parseInt, isInteger, sumBy } from 'lodash-es'

export default class extends Controller {

  static targets = ['fundInput', 'budgetTotal']

  recalculateTotal() {
    if (!this.hasBudgetTotalTarget) {
      console.error('unable to update budget total')
      return
    }

    const formattedTotal = new Intl.NumberFormat('en-GB',
      { style: 'currency', currency: 'GBP', minimumFractionDigits: 0 }
    ).format(this.currentTotal())

    this.budgetTotalTarget.textContent = formattedTotal
  }

  currentTotal() {
    return sumBy(this.fundInputTargets, function (elem) {
      const total = parseInt(elem.value)
      return isInteger(total) ? total : 0
    })
  }

}
