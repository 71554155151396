import { Controller } from "@hotwired/stimulus"
import {get} from "lodash-es";
import { DragDropUpload } from "../../../lib/drag_drop_upload";

export default class extends Controller {

  static targets = [
    'validationMessage',
    'fileDragZone',
    'fileInput',
    'uploadForm',
    'uploadName',
    'uploadField',
    'uploadAreaContainer',
    'publishButton',
    'publishButtonSpinner'
  ]

  static values = {
    maxUploadCount: Number,
    locationId: String,
    folderId: String,
  }

  initialize() {
    this.currentlyUploadingCount = 0;
  }

  connect() {
    this.addEventListeners()

    this.resetFileInput();
    this.showHideDragField();
  }

  addEventListeners() {
    document.addEventListener('uploadErrorEvent', this.handleUploadError.bind(this));
    document.addEventListener(`uploadFieldReady`, this.uploadFieldConnected.bind(this));
    document.addEventListener(`uploadRemoved`, this.uploadRemoved.bind(this));
    document.addEventListener(`uploadStarted`, this.uploadStarted.bind(this));
    document.addEventListener(`uploadFinished`, this.uploadFinished.bind(this));
  }

  upload(files) {
    let validatedFiles = {};

    // Reset things that need resetting
    this.resetValidationMessage();

    // Check if files exists
    if (files.length == 0) {
      // Hide the form fields and show the drop box
      this.showValidationMessage("Please select a file first");
    } else {
      // Get valid files
      validatedFiles = this.validateFiles(files);

      // Handle direct upload
      for (let i = 0; i < validatedFiles.validFiles.length; i++) {
        const fieldId = this.generateUniqueId();

        // Creates listeners for ready fields, queues uploads, and adds from queue to ready fields
        document.addEventListener(`uploadFieldReady_${fieldId}`, () => {
          this.uploadFileViaChild(files[i], fieldId);
        })
        this.appendUploadDetails(fieldId);
      }
    }

    this.resetFileInput();

    this.showInvalidFiles(validatedFiles);
  }

  generateUniqueId() {
    const timestamp = new Date().getTime();
    const random = Math.random().toString(36).substr(2, 9);
    return `upload_field_${timestamp}_${random}`;
  }

  appendUploadDetails(fieldId) {
    // Add a new card/fields - called once per new upload
    const template = document.getElementById('file-details-template');
    let clone = template.cloneNode(true);

    clone.innerHTML = clone.innerHTML.replace(/NEW_RECORD/g, fieldId);

    this.uploadAreaContainerTarget.append(clone.content);
  }

  uploadFileViaChild(file, fieldId) {
    // Dispatches 'uploadEvent' which child controllers on each field listen for
    // Child controllers then upload their respective files
    const uploadEvent = new CustomEvent(`uploadEvent_${fieldId}`, {
      detail: {
        file: file,
        fieldId: fieldId,
        locationId: this.locationIdValue,
        folderId: this.folderIdValue,
      }
    })
    document.dispatchEvent(uploadEvent);
  }

  fileSelected(fileToUpload) {
    this.file = fileToUpload;
  }

  uploadStarted() {
    this.currentlyUploadingCount += 1;
    this.showHidePublishButton();
  }

  uploadFinished() {
    this.currentlyUploadingCount -= 1;
    this.showHidePublishButton();
  }

  uploadRemoved() {
    this.resetFileInput();
  }

  uploadFieldConnected() {
    this.showHideDragField();

    this.showHidePublishButton();
  }

  resetValidationMessage() {
    this.hideItem(this.validationMessageTarget);
  }

  showValidationMessage(msg) {
    this.showItem(this.validationMessageTarget);
    this.validationMessageTarget.innerHTML = msg;
  }

  validateFiles(files) {
    let validatedFiles = {
      validFiles: [],
      totalErrors: 0,
      poorFileFormat: [],
      exceedSizeLimits: [],
      exceedMaxUploadCount: [],
      uniqueInvalidFiles: new Set()
    };

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      let fileName = this.cleanFileName(file.name);

      if (this.acceptedFileFormat(fileName) && this.withinSizeLimits(file) && this.wontExceedMaxUploadCount(file, i)) {
        validatedFiles.validFiles.push(file);
      } else {
        if (!this.acceptedFileFormat(fileName)) {
          validatedFiles.totalErrors += 1;
          validatedFiles.poorFileFormat.push(file);

          validatedFiles.uniqueInvalidFiles.add(file);
        }
        if (!this.withinSizeLimits(file)) {
          validatedFiles.totalErrors += 1;
          validatedFiles.exceedSizeLimits.push(file);

          validatedFiles.uniqueInvalidFiles.add(file);
        }
        if (!this.wontExceedMaxUploadCount(file, i)) {
          validatedFiles.totalErrors += 1;
          validatedFiles.exceedMaxUploadCount.push(file);

          validatedFiles.uniqueInvalidFiles.add(file);
        }
      }
    }

    return validatedFiles;
  }

  showInvalidFiles(validatedFiles) {
    // Currently just displays a list of any invalid files without categorising for now (possible to categorise with the above though)
    if (validatedFiles.totalErrors > 0) {
      let html = '<p>There was an issue with uploading the following files. Please ensure that the format and size of these files is correct, and that the number of files does not exceed the maximum number permitted.</p>';

      validatedFiles.uniqueInvalidFiles.forEach(file => {
        html += `<p class="mb-0">${this.limitFilenameLength(file.name)} (${this.bytesToKB(file.size)})</p>`;
      })

      this.showValidationMessage(html);
    }
  }

  cleanFileName(name) {
    return name.replace(/^\s+|\s+$/g, '')
      .replace(/[^a-z0-9\.\-\_ ]/gi, '')
      .replace(/[-\s]+/g, '-');
  }

  acceptedFileFormat(file_name) {
    const accepted_formats = ['pdf', 'doc', 'docx', 'pages', 'xls', 'xlsm', 'xlsx', 'ppt', 'pptx', 'numbers', 'key', 'jpg', 'jpeg', 'png', 'odt', 'ods', 'opd'];

    let ext = file_name.toLowerCase().match(/\.([^\.]+)$/)[1];

    if (accepted_formats.includes(ext)) {
      return true;
    } else {
      return false;
    }
  }

  withinSizeLimits(file) {
    const gigabyte = 1024 * 1024 * 1024;

    if (file.size > 1 * gigabyte) {
      return false;
    } else {
      return true;
    }
  }

  wontExceedMaxUploadCount(file, filePosition) {
    const maxUploadCount = this.maxUploadCountValue;

    if (this.uploadCount() + filePosition + 1 <= maxUploadCount) {
      return true;
    } else {
      return false;
    }
  }

  handleUploadError() {
    this.showValidationMessage("Sorry, an error occurred when uploading. Please try again");
  }

  csrfToken() {
    const csrfToken = document.querySelector("[name='csrf-token']");

    return get(csrfToken, 'content');
  }

  bytesToKB(bytes) {
    const kilobytes = bytes / 1024;
    return kilobytes.toFixed(2) + ' KB';
  }

  limitFilenameLength(filename, maxLength = 30) {
    if (filename.length <= maxLength) return filename;

    const start = Math.floor((maxLength - 3) / 2);
    const end = Math.ceil((maxLength - 3) / 2);

    return `${filename.slice(0, start)}...${filename.slice(-end)}`;
  }

  resetFileInput() {
    const template = document.getElementById('file-upload-area-template');
    let clone = template.cloneNode(true);

    this.fileDragZoneTarget.innerHTML = '';
    this.fileDragZoneTarget.appendChild(clone.content);

    new DragDropUpload(this.element, this.dragDropCallback.bind(this));
  }

  showHideDragField() {
    if (this.uploadCount() > 0) {
      this.hideItem(this.fileDragZoneTarget)
    } else {
      this.showItem(this.fileDragZoneTarget)
    }
  }

  handlePublish(event) {
    event.preventDefault();

    let errors = [];
    let names = new Set();

    this.uploadNameTargets.forEach(uploadName => {
      const inputName = uploadName.value.trim();
      let error = null;

      if (inputName === '') {
        error = 'Name cannot be blank';
      } else if (names.has(inputName)) {
        error = 'Names must be unique';
      }

      if (error) {
        errors.push({ uploadName, error });
      } else {
        names.add(inputName); // Only add to the set if there's no error
      }
    });

    this.showPublishButton(false);

    if (errors.length === 0) {
      this.uploadFormTarget.submit(); // Submit the form if no errors
    } else {
      this.displayErrors(errors);
    }
  }


  displayErrors(errors) {
    this.clearExistingErrors();

    errors.forEach(({ uploadName, error }) => {
      const errorElement = document.createElement('small');
      errorElement.classList.add('error', 'name-error', 'error-text');
      errorElement.textContent = error;

      uploadName.parentNode.insertBefore(errorElement, uploadName.nextSibling);

      this.showPublishButton(true);
    });
  }


  clearExistingErrors() {
    const existingErrors = this.element.querySelectorAll('.error-text');
    existingErrors.forEach(error => error.remove());
  }


  showHidePublishButton() {
    if (this.uploadCount() > 0 && this.currentlyUploadingCount == 0) {
      this.showItem(this.publishButtonTarget)
    } else {
      this.hideItem(this.publishButtonTarget)
    }
  }

  dragDropCallback(file) {
    this.fileSelected(file);
    this.upload(file);
  }

  uploadCount() {
    return document.querySelectorAll('.upload.new').length;
  }

  showPublishButton(showSpinner) {
    if (showSpinner == false) {
      this.hideItem(this.publishButtonTarget);
      this.showItem(this.publishButtonSpinnerTarget);
    } else {
      this.showItem(this.publishButtonTarget);
      this.hideItem(this.publishButtonSpinnerTarget);
    }
  }

  selectButtonClick() {
    this.fileInputTarget.click();
  }

  showItem(item) {
    item.classList.remove('hide');
  }

  hideItem(item) {
    item.classList.add('hide');
  }
}
