import { Controller } from "@hotwired/stimulus"
import { get } from "lodash-es"

export default class extends Controller {
  static targets = [
    "assetSection",
    "baseUrl"
  ]

  connect() {
    this.setDefaultLocationAndZone();

    document.addEventListener('location:changed', this.locationChange.bind(this));
    document.addEventListener('locationZone:changed', this.locationZoneChange.bind(this));
  }

  disconnect() {
    document.removeEventListener('location:changed', this.locationChange);
    document.removeEventListener('locationZone:changed', this.locationZoneChange);
  }

  setDefaultLocationAndZone() {
    const locationId = document.getElementById('location-select').value;
    const locationZoneId = get(document, "getElementById('location-zone-select').value");
    this.setLocationAndZone(locationId, locationZoneId);

    this.regenerateRefreshUrl();
  }

  locationChange(event) {
    this.setLocationAndZone(event.detail.locationId, event.detail.locationZoneId);

    this.regenerateRefreshUrl();
  }

  locationZoneChange(event) {
    this.setLocationAndZone(event.detail.locationId, event.detail.locationZoneId);

    this.regenerateRefreshUrl();
  }

  setLocationAndZone(locationId, locationZoneId) {
    this.locationId = locationId;
    this.locationZoneId = locationZoneId;
  }

  regenerateRefreshUrl() {
    if (!this.hasAssetSectionTarget) {
      return;
    }

    const baseUrl = this.baseUrlTarget.value;
    let refreshUrl = baseUrl;

    if (this.locationId && this.locationZoneId) {
      refreshUrl = `${baseUrl}?location_id=${this.locationId}&location_zone_id=${this.locationZoneId}`;

    } else if (this.locationId) {
      refreshUrl = `${baseUrl}?location_id=${this.locationId}`;

    } else if (this.locationZoneId) {
      refreshUrl = `${baseUrl}?location_zone_id=${this.locationZoneId}`;
    }

    this.assetSectionTarget.src = null;
    this.assetSectionTarget.src = refreshUrl;
  }
}
