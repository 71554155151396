import { Controller } from "@hotwired/stimulus"
import { debounce } from 'lodash-es'

export default class extends Controller {
  static targets = [
    "searchForm"
  ]

  connect() {
    this.debouncedSubmit = debounce(this.submitForm.bind(this), 250)
  }

  search(event) {
    event.preventDefault();
    this.debouncedSubmit();
  }

  submitForm() {
    this.searchFormTarget.requestSubmit();
  }
}
