import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['toggleIcon', 'options']

  static values = {
    open: { type: Boolean, default: false }
  }


  toggleOptions () {
    if (this.toggleIconTarget.classList.contains('fa-plus')) {
      this.toggleIconTarget.classList.remove('fa-plus')
      this.toggleIconTarget.classList.add('fa-minus')
      this.optionsTarget.classList.remove('hide');
      this.openValue = true
    } else {
      this.toggleIconTarget.classList.remove('fa-minus')
      this.toggleIconTarget.classList.add('fa-plus')
      this.openValue = false
      this.optionsTarget.classList.add('hide');
    }
  }
 }
