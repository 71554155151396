import { Controller } from "stimulus"
import Muuri from "muuri"

export default class extends Controller {
  static get classes() {
    return ['hide', 'loaded']
  }

  static get targets() {
    return ['editButton', 'filter', 'search', 'tiles', 'noContent']
  }

  static get values() {
    return { drag: false, layouts: [], searchField: '', filterField: '' }
  }

  connect() {
    let _this = this
    this.grid = new Muuri('.insights-grid', {
      dragEnabled: true,
      fillGaps: false,
      dragStartPredicate: function (item, event) {
        if (_this.dragValue === true) {
          return Muuri.ItemDrag.defaultStartPredicate(item, event);
        } else {
          return undefined;
        }
      }
    });

    window.addEventListener('add-tile-item', (e) => {
      this.addItem(e.detail.newItem);
    })

    window.addEventListener('edit-tile-item', (e) => {
      this.editItem(e.detail.itemId);
    })

    window.addEventListener('turbo:remove', (e) => {
      this.removeItem(e)
    });

    this.grid.layout(true);
    if (!this.tilesTarget.classList.contains(this.loadedClass)) {
      this.tilesTarget.classList.add(this.loadedClass)
    }

    this.updateLayoutValue();
    this.toggleNoContent();
  }

  toggleNoContent() {
    if (this.grid.getItems().length == 0 && !this.hasNoContentTarget) {
      const parentElement = document.createElement("div");
      const textNode = document.createElement("h5");
      parentElement.className = 'warning-box insights-no-content'
      parentElement.setAttribute("data-grid-target", "noContent");
      textNode.innerHTML = 'No tiles to show. Click "Add Tile" to begin populating the dashboard'
      parentElement.appendChild(textNode);

      this.tilesTarget.appendChild(parentElement)
    } else if (this.hasNoContentTarget) {
      this.noContentTarget.remove();
    }
  }

  updateLayoutValue() {
    this.layoutValue = JSON.parse(this.serializeLayout(this.grid));
  }

  addItem(itemId) {
    const newItem = document.querySelector(`[data-id='${itemId}']`);

    if (this.grid.getItem(newItem) === null) {
      this.grid.add(newItem, { layout: true, active: true, index: 0 })
      this.updateLayoutValue();
      this.toggleNoContent();
    }
  }

  filter(onFinish = null) {
    this.filterFieldValue = this.filterTarget.value
    this.searchFieldValue = this.searchTarget.value.toLowerCase()
    this.grid.filter(
      (item) => {
        const element = item.getElement();
        const isSearchMatch =
          !this.searchFieldValue ||
          (element.getAttribute('data-title') || '').toLowerCase().indexOf(this.searchFieldValue) > -1;
        const isFilterMatch =
          !this.filterFieldValue || this.filterFieldValue === element.getAttribute('data-category');
        return isSearchMatch && isFilterMatch;
      },
      { onFinish: onFinish }
    );
  }

  toggleEditLayout() {
    this.dragValue = !this.dragValue

    if (this.dragValue === true) {
      this.editButtonTarget.textContent = 'Done'
      this.element.classList.add('editing')
    } else {
      this.editButtonTarget.textContent = 'Edit'
      this.layoutsValue.length = 0
      this.element.classList.remove('editing')
    }
  }

  editItem(item) {
    const newItem = document.getElementById(`tile-${item}`)
    const layout = this.serializeLayout();
    const layoutArray = JSON.parse(layout);
    const itemIndex = layoutArray.indexOf(`${item}`)
    this.grid.remove(this.grid.getItems(itemIndex))
    this.grid.add(newItem, { index: itemIndex })
  }

  removeItem(e) {
    const item = this.grid.getItem(e.target.closest('.insights-grid-item'))

    let confirmed = confirm("Are you sure you want to remove the report?")

    if (!confirmed) {
      e.preventDefault();
      return
    }

    this.grid.hide([item], {
      onFinish: (item) => {
        this.grid.remove(item, { removeElements: false });
        this.toggleNoContent();
      },
    });
    this.updateLayoutValue();
  }

  serializeLayout() {
    let itemIds = this.grid.getItems().map(function (item) {
      return item.getElement().getAttribute('data-id');
    });
    return JSON.stringify(itemIds);
  }
}