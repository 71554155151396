import { Application } from "@hotwired/stimulus";
import { loadStimulusDefinitionsFromContexts } from "../../lib/stimulus";
import { CheckboxSelectAllController, DetectDirtyFormController, FormRcController, RemoteFormController, SyncInputsController, TreeViewController } from "stimulus-library";

const application = Application.start();

// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.
loadStimulusDefinitionsFromContexts(
  application,
  require.context("controllers/shared", true, /_controller\.js$/),
  require.context("controllers/app", true, /_controller\.js$/),
);

application.debug = process.env.RAILS_ENV === "development";
application.register('detect-dirty-form', DetectDirtyFormController);
application.register('checkbox-select-all', CheckboxSelectAllController);
application.register('form-rc', FormRcController);
application.register('remote-form', RemoteFormController);
application.register('sync-inputs', SyncInputsController);
application.register('tree-view', TreeViewController);
