import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    'singleUserSection',
    'multipleUsersSection',
    'importOptionHiddenField',
    'importOption',
    'searchResults',
    'noResults',
    'result',
    'bulkSubmit'
  ]

  connect() {
    this.checkResultsPresent()
    document.addEventListener('groupMembershipLoaded', this.checkResultsPresent.bind(this))
    document.addEventListener('groupMembershipDeleted', this.checkResultsPresent.bind(this))
  }

  showHideSections(e) {
    if (e.target.value === 'single_user') {
      this.singleUserSectionTarget.style.display = 'block'
      this.multipleUsersSectionTarget.style.display = 'none'
    } else if (e.target.value === 'multiple_users') {
      this.singleUserSectionTarget.style.display = 'none'
      this.multipleUsersSectionTarget.style.display = 'block'
    }

    this.broadcastSelectedOption(e.target.value)
  }

  revealSubmit(e) {
    this.bulkSubmitTarget.style.display = 'block'
  }

  broadcastSelectedOption(newValue) {
    this.importOptionHiddenFieldTargets.forEach((field) => (field.value = newValue))
  }

  checkResultsPresent() {
    if (this.resultTargets.length > 0) {
      this.searchResultsTarget.style.display = 'block'
      this.noResultsTarget.style.display = 'none'
    } else {
      this.searchResultsTarget.style.display = 'none'
      this.noResultsTarget.style.display = 'block'
    }
  }
}
