import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  static get targets() {
    return [
      'lostDaysDiv', 'lostDaysInput'
    ];
  }


  connect() {
  }

  domShow(e) {
    e.classList.add('visible');
    e.classList.remove('hidden');
  }

  domHide(e) {
    e.classList.add('hidden');
    e.classList.remove('visible');
  }
}
