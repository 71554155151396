import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

	static get targets() {
		return [
			"addButton",
			"autoComplete",
			"table",
			"invites",
			"noResults",
		];
	}

	connect() {
		console.log("Stimulus#App/ResponseGroup/FormController Connected to ", this);
		this.testNoResults();
	}

	testNoResults() {
		if (this.invitesTargets.length === 0) {
			this.showNoResults();
		} else {
			this.hideNoResults();
		}
	}

	showNoResults() {
		let row = this.tableTarget.insertRow();
		row.dataset["target"] = "response-group--form.noResults";
		let cell = row.insertCell();
		cell.setAttribute("colspan", "2");
		cell.innerHTML = "No invites added yet";
	}

	hideNoResults() {

		this.noResultsTargets.forEach(e => e.remove());
	}

	deleteInvite(e) {
		e.target.closest('tr').remove();
		this.testNoResults();
	}

	addInvite(e) {
		e.preventDefault();

		let email_field = application.controllers[0].autoCompleteTarget.querySelector("input");
		let email = email_field.value;
		if (this.invitesTargets.some((e) => e.querySelector('input').value === email)) {
			return; // Prevent duplicates
		}

		let row = this.tableTarget.insertRow();
		row.dataset["target"] = "response-group--form.invites";
		row.dataset["rowId"] = this.invitesTargets.reduce((carry, el) => {
			console.log(el, carry);
			return Math.max(carry, el.dataset.rowId || 0);
		}, 0) + 1;

		let cell1 = row.insertCell();
		cell1.innerHTML = "<p>" + email + "</p><input name='group[invites][]' type='hidden' value='" + email + "'/>";

		let cell2 = row.insertCell();
		cell2.innerHTML = "<i class='fa fa-times fa-lg error-text' data-action='click->response-group--form#deleteInvite' ></i>";
		this.testNoResults();
		email_field.value = "";
	}


}
