import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['from', 'to'];

  initialize() {
    this.handleMutation = this.handleMutation.bind(this);
  }

  connect() {
    this.config = {
      attributes: true,
      attributeFilter: ['value'],
    };

    this.observer = new MutationObserver(this.handleMutation);
    this.observer.observe(this.fromTarget, this.config);
    this.observer.observe(this.toTarget, this.config);
  }

  disconnect() {
    this.observer.disconnect();
  }

  handleMutation(mutationList, observer) {
    const lastChange = mutationList.pop();
    const fromChanged = lastChange.target == this.fromTarget;

    this.observer.disconnect();
    if (fromChanged) {
      this.toTarget._flatpickr.set('minDate', this.fromTarget.value);
    } /* toChanged */ else {
      this.fromTarget._flatpickr.set('maxDate', this.toTarget.value);
    }
    this.observer.observe(this.fromTarget, this.config);
    this.observer.observe(this.toTarget, this.config);
  }
}
