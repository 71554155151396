import { Controller } from "stimulus";
import { throttle } from "lodash";

export default class extends Controller {
  static targets = [ "form", "data" ]
  static outlets = [ "video" ]
  static values = { initialPercentage: { type: Number, default: 0 } }

  connect() {
    if (this.hasVideoOutlet) {
      this.setupPlayerListeners();
    }
  }
  
  setupPlayerListeners() {
    this.player = this.videoOutlet.player
    this.player.once('canplay', this.startAtLastWatched.bind(this)); // HTML5 start
    this.player.once('ready', this.startAtLastWatched.bind(this)); // Embedded start
    this.player.on('timeupdate', throttle(this.updateProgress.bind(this), 2000)); // Once every 2 seconds
    this.player.on('ended', this.handleVideoEnd.bind(this));
  }

  disconnect() {
    if (this.hasVideoOutlet) {
      this.player.off('timeupdate', this.updateProgress.bind(this));
    }
  }

  handleVideoEnd() {
    this.saveProgress(100);
  }

  startAtLastWatched() {
    const duration = this.player.duration;
    const lastWatched = this.initialPercentageValue;
    const targetTime = (duration * lastWatched) / 100;
    this.player.currentTime = targetTime;
  }

  updateProgress() {
    const currentTime = this.player.currentTime;
    const duration = this.player.duration;
    const progress = (currentTime / duration) * 100;
    this.saveProgress(progress);
  }

  saveProgress(percentageWatched) {
    if (this.hasDataTarget) {
      this.dataTarget.value =  `{ "percentage_watched": ${percentageWatched} }`;
      this.formTarget.requestSubmit();
    }
  }
}
