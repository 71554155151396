import { RemoteFormController } from 'stimulus-library'

export default class extends RemoteFormController {

  static targets = ["form", "emailConfirmation"]

  showEmailConfirmation(event) {
    this.emailConfirmationTarget.classList.remove('hide')
  }

  userDetailsSuccess(event) {
    window.location.reload(true)
  }
}
