import { BaseController } from 'stimulus-library';

export default class extends BaseController {
  static values = {
    url: String,
  };
  static targets = ['location', 'zone', 'zonesList'];

  initialize() {
    this.locationChangedHandler = this.locationChangedHandler.bind(this);
  }

  connect() {
    this.locationTarget.addEventListener('change', this.locationChangedHandler);
    this.locationChangedHandler();
  }

  disconnect() {
    this.locationTarget.addEventListener('change', this.locationChangedHandler);
  }

  locationChangedHandler() {
    this.updateLocationZones();
    this.locationChanged();
  }

  async updateLocationZones() {
    if (this.locationTarget.value == '' || !this.hasZoneTarget || !this.hasZonesListTarget) {
      return;
    }

    const zonesData = JSON.parse(this.zonesListTarget.innerHTML);

    let zoneOptions = zonesData[this.locationTarget.value];
    if (zoneOptions) {
      this.zoneTarget.innerHTML = zoneOptions;
    }
  }

  locationChanged() {
    document.dispatchEvent(new CustomEvent('location:changed', {
      detail: { locationId: this.location(), locationZoneId: this.zone() }
    }));
  }

  locationZoneChanged() {
    document.dispatchEvent(new CustomEvent('locationZone:changed', {
      detail: { locationId: this.location(), locationZoneId: this.zone() }
    }));
  }

  location() {
    if (this.hasLocationTarget) {
      return this.locationTarget.value;
    }

    return '';
  }

  zone() {
    if (this.hasZoneTarget) {
      return this.zoneTarget.value;
    }

    return '';
  }
}
