import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static outlets = ['grid']

  static get targets() {
    return ['positionForm', 'positionInput']
  }

  connect() {
    this.element.addEventListener('mouseup', this.handleMouseUp.bind(this));
  }

  handleMouseUp(event) {
    const itemElement = event.target.closest('.insights-grid-item');
    if (itemElement && this.hasPositionFormTarget && this.hasPositionInputTarget) {
      const itemId = itemElement.dataset.id;
      let oldLayout = this.gridOutlet.layoutValue;
      let oldPosition = oldLayout.indexOf(itemId) + 1;
      let layout = this.gridOutlet.serializeLayout();
      let newPosition = JSON.parse(layout).indexOf(itemId) + 1

      if (newPosition != oldPosition) {
        this.positionInputTarget.value = newPosition

        this.positionFormTarget.requestSubmit();
      }

    }
  }
}