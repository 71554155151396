import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static outlets = ["shared--button-dropdown"]

  static targets = ['dropdownContent', 'dropdownBackground']

  static classes = ['mobile-dropdown']

  static values = {
    open: Boolean
  }

  toggle(e) {
    const el = e.target

    // Prevent event firing if button is of type submit since this is a dropdown element
    if (el.tagName === 'BUTTON') {
      const buttonType = el.getAttribute('type');
      if (buttonType === 'submit') {
        e.preventDefault();
      }
    }

    if (this.isTouchDevice()) {
      // Toggle for mobile navigation
      if (e.type === 'click') {
        e.preventDefault();
        this.toggleDropdown();
      }
    } else {
      if (e.type == "mouseover") {
        // Prevents unwanted toggle when rolling over already opened item (header witha a badge)
        if (this.openValue == false) {
          this.toggleDropdown();
        }
      } else {
        this.toggleDropdown();
      }
    }
  }

  isTouchDevice() {
    return ('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0);
  }

  toggleDropdown() {
    if (this.openValue === true) {
      // Hide this dropdown if it's already open
      this.hide();
    } else {
      // Close all other dropdowns
      this.sharedButtonDropdownOutlets.forEach(dropdown => {
        // Only hide the dropdown if it's not the current one
        if (dropdown !== this) {
          dropdown.hide();
        }
      });
      // Show this dropdown
      this.show();
    }
  }

  hide() {
    this.openValue = false;
    this.dropdownContentTarget.classList.add('hide');
    this.dropdownBackgroundTarget.classList.add('hide');
  }

  show() {
    this.openValue = true;
    this.dropdownContentTarget.classList.remove('hide');
    this.dropdownBackgroundTarget.classList.remove('hide');
  }
}
