import DateRangeController from './datepicker_from_and_to_controller';

export default class extends DateRangeController {
  static targets = ['from', 'to', 'option'];

  connect() {
    super.connect();
    this.handleMultiDateOptionChange = this.handleMultiDateOptionChange.bind(this);
    if (this.hasOptionTarget) {
      this.optionTarget.addEventListener('change', this.handleMultiDateOptionChange);
    }
  }

  disconnect() {
    super.disconnect();
    if (this.hasOptionTarget) {
      this.optionTarget.removeEventListener('change', this.handleMultiDateOptionChange);
    }
  }

  handleMutation(mutationList, observer) {
    super.handleMutation(mutationList, observer);
    this.syncDates();
  }

  handleMultiDateOptionChange() {
    this.syncDates();
  }

  syncDates() {
    if (this.hasOptionTarget && !this.optionTarget.checked) {
      // Temporarily disconnect the observer to prevent a fun infinite loop
      this.observer.disconnect();
      this.toTarget._flatpickr.setDate(this.fromTarget.value);
      // Reconnect observer
      this.observeTargets();
    }
  }

  observeTargets() {
    this.observer.observe(this.fromTarget, this.config);
    this.observer.observe(this.toTarget, this.config);
  }
}
