import AssocationSelectorController from '../../shared/form/association_selector_controller'
import { get, clone, replace } from 'lodash-es'

export default class extends AssocationSelectorController {

  onSelect(e) {
    e.preventDefault()

    const meterJson = get(e, 'currentTarget.dataset.meter', '')
    if (meterJson.length == 0) {
      console.error('No meter data detected')
    } else {
      const meterData = JSON.parse(meterJson)
      if (this.hasRedirectPathValue) {
        this.redirectTo(meterData)
      } else {
        this.fireCustomEvent(meterData)
      }

      $(this.element).foundation('reveal', 'close')
    }
  }

  redirectTo(meterData) {
    let redirect = clone(this.redirectPathValue)

    const meterId = get(meterData, 'scoped_id', 0)
    if (meterId > 0) {
      redirect = replace(redirect, ':meter_id', meterId)
    } else {
      redirect = replace(redirect, '/:meter_id', '')
    }

    const locationId = get(meterData, 'location.scoped_id', 0)
    if (locationId > 0) {
      redirect = replace(redirect, ':location_id', locationId)
    } else {
      redirect = replace(redirect, '/:location_id', '')
    }

    window.location.href = redirect
  }

  fireCustomEvent(meterData) {
    window.dispatchEvent(new CustomEvent('utility-meter-selected', { detail: { meter: meterData } }))
  }

}
