import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  initialize() {
    this.deleted = false
  }

  connect() {
    const groupMembershipLoaded = new CustomEvent('groupMembershipLoaded')
    document.dispatchEvent(groupMembershipLoaded)
  }

  disconnect() {
    const groupMembershipDeleted = new CustomEvent('groupMembershipDeleted')
    document.dispatchEvent(groupMembershipDeleted)
  }

  delete(event) {
    if (this.deleted === true) {
      event.preventDefault()
    }

    this.deleted = true
  }
}
