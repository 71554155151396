import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    locationCount: Number
  }

  static targets = [
    "resultsContainer",
    "toggleButton",
    "searchInput",
    "sortOrder",
    "spinner"
  ]

  initialize() {
    // Keep track of page state
    this.resultsRendering = false
    this.allFramesRendered = false
  }

  connect() {
    // Set collapse/expanded state
    this.setCollapseState()

    // Check when page is being populated
    document.addEventListener('start-of-frames', this.handleFramesRendering.bind(this))

    // Only run when all frames have loaded and page ready
    document.addEventListener('end-of-frames', this.handleFramesLoaded.bind(this))
  }

  handleFramesRendering() {
    this.resultsRendering = true
  }

  handleFramesLoaded() {
    // Enable inputs once loaded
    this.allFramesRendered = true
    this.toggleButtonTarget.removeAttribute("disabled")
    this.searchInputTarget.removeAttribute("disabled")
    this.sortOrderTarget.removeAttribute("disabled")

    this.setCollapseState()

    // Setup live search
    $("#filter_locations").live_search({
      rowSelector: '.search-result',
      itemSelector: '.health-overview h1',
      useStripes: false,
      excludeItems: '',
      list: {
        _check_no_data: this._check_no_data,
        _is_row_visible: this._is_row_visible
      }
    })

    this.removeSpinner()
  }

  toggle() {
    // Only run when page is fully loaded
    if (this.allFramesRendered) {
      if (this.element.classList.contains('collapsed')) {
        this.element.classList.remove('collapsed')
      } else {
        this.element.classList.add('collapsed')
      }

      this.setCollapseState()
    }
  }

  setCollapseState() {
    // Change collapsed state of all items
    const items = this.element.querySelectorAll(".search-result")

    items.forEach(item => {
      if (this.element.classList.contains('collapsed')) {
        item.querySelector("section").style.display = "none"

        this.toggleButtonTarget.innerHTML = "<span>Expand </span><i class='fa fa-caret-right'></i>"
      } else {
        item.querySelector("section").style.display = "block"

        this.toggleButtonTarget.innerHTML = "<span>Collapse </span><i class='fa fa-times'></i>"
      }
    })
  }

  sort() {
    const sortOrder = this.sortOrderTarget.value

    const elementsArray = [...this.element.querySelectorAll(".search-result")]
    const resultsContainer = this.resultsContainerTarget

    elementsArray.sort((a, b) => {
      a = a.querySelector('#health-score')
      b = b.querySelector('#health-score')
      if (sortOrder === "scorea") {
        return a.textContent.localeCompare(b.textContent, 'en', {numeric: true})
      } else if (sortOrder === "scored") {
        return b.textContent.localeCompare(a.textContent, 'en', {numeric: true})
      }
    })

    resultsContainer.innerHTML = ""
    elementsArray.forEach((element) => {
      resultsContainer.appendChild(element)
    })
  }

  addSpinner() {
    this.spinnerTarget.style.display = 'flex'
  }

  removeSpinner() {
    this.spinnerTarget.style.display = 'none'
  }

  _check_no_data() {
    $('.search-result:visible').length != 0 ? $('.no-results').hide() : $('.no-results').show()
  }
  _is_row_visible(row) {
    return !row.hasAttribute('data-misses-search') ? true : false
  }
}
