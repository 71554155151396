import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    setTimeout(this.resetErrors.bind(this), 3000)
  }

  resetErrors() {
    this.element.classList.remove("error")
    this.element.querySelectorAll("button.error").forEach(function (el) {
      el.classList.remove("error")
    })
  }
}