import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["originalElement", "spinner"];

  connect() {
    document.addEventListener('search:results-loaded', this.hideSpinner.bind(this));
  }

  disconnect() {
    document.removeEventListener('search:results-loaded', this.hideSpinner.bind(this));
  }

  revealSpinner() {
    this.spinnerTarget.classList.remove("hide");

    if (this.originalElementTargets.length > 0) {
      const lastOriginalElement = this.originalElementTargets[this.originalElementTargets.length - 1];
      lastOriginalElement.classList.add("hide");
    }
  }

  hideSpinner() {
    this.spinnerTarget.classList.add("hide");

    if (this.originalElementTargets.length > 0) {
      const lastOriginalElement = this.originalElementTargets[this.originalElementTargets.length - 1];
      lastOriginalElement.classList.remove("hide");
    }
  }
}
