import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  showJobForm(event) {
    let [data, status, xhr] = event.detail

    document.querySelector('.action-point-comment-form-container').classList.add('hide')

    this.renderJobForm(xhr.response)

    document.querySelector('.action-point-raise-job-container').classList.remove('hide')
    this.scrollToJobForm()
    this.closeModalIfPresent()
  }

  renderJobForm(responseHtml) {
    let formContainer = document.querySelector('.action-point-raise-job-container .job-form-container')
    formContainer.innerHTML = responseHtml

    let cancelButton = formContainer.querySelector("#cancel-job")
    cancelButton.setAttribute("data-action", "click->action-points--jobs#cancelJobForm")

    let jobForm = formContainer.querySelector("form.job-form")

    jobForm.setAttribute("data-controller", "action-points--jobs")
    jobForm.setAttribute("data-action", "ajax:error->action-points--jobs#handleJobFormError ajax:success->action-points--jobs#remoteFormSuccess")
  }

  scrollToJobForm() {
    let scrollPosition = document.querySelector('.action-point-raise-job-container').offsetTop + 150
    window.scrollTo({
      top: scrollPosition,
      behavior: "smooth",
    })
  }

  cancelJobForm(event) {
    event.preventDefault()
    event.stopPropagation()

    document.querySelector('.action-point-comment-form-container').classList.remove('hide')

    let formContainer = document.querySelector('.action-point-raise-job-container .job-form-container')
    formContainer.querySelector("#cancel-job").removeEventListener("click", this.cancelJobForm)
    formContainer.innerHTML = ""

    document.querySelector('.action-point-raise-job-container').classList.add('hide')
  }

  handleJobFormError(event) {
    let [_response, _status, xhr] = event.detail

    this.renderJobForm(xhr.response)
    this.scrollToJobForm()
  }

  remoteFormSuccess(event) {
    let [_response, _status, xhr] = event.detail

    let redirect_url = xhr.getResponseHeader("X-Ajax-Redirect-Url")
    if (redirect_url != undefined) {
      window.location.href = redirect_url
    }
  }

  closeModalIfPresent() {
    // Returns if modal not used
    let modal = document.querySelector('.reveal-modal-bg')

    if (!modal) {
      return
    }

    $('a.close-reveal-modal').trigger('click')
  }
}
