import { Controller } from "@hotwired/stimulus"
import { get, uniq, split, join, compact, without, isEmpty } from "lodash-es"

export default class extends Controller {

  static values = {
    summary: Object
  }

  connect() {
    if (this.hasSummaryValue && !isEmpty(this.summaryValue)) {
      window.dispatchEvent(new CustomEvent('affected-assets-search-loaded', { detail: this.summaryValue }))
    }
  }

  excludeEntry(e) {
    const evtAssetInstanceId = get(e, 'detail.asset_instance_id')
    if (evtAssetInstanceId.length == 0) {
      return
    }

    const excludedIdInput = this.element.querySelector('input[name=exclude_asset_ids]')
    let excludedIds = compact(split(excludedIdInput.value, ','))
    excludedIds.push(evtAssetInstanceId)
    excludedIdInput.value = join(uniq(excludedIds), ',')

    setTimeout(this.refresh.bind(this), 500)
  }

  includeEntry(e) {
    const evtAssetInstanceId = get(e, 'detail.asset_instance_id')
    if (evtAssetInstanceId.length == 0) {
      return
    }

    const excludedIdInput = this.element.querySelector('input[name=exclude_asset_ids]')
    let excludedIds = compact(split(excludedIdInput.value, ','))
    excludedIdInput.value = join(without(excludedIds, evtAssetInstanceId), ',')

    this.refresh()
  }

  zoneSelected(e) {
    const zoneInput = this.element.querySelector('input[name=location_zone_id]')
    if (!zoneInput) {
      return
    }

    zoneInput.value = get(e, 'detail.zone.id')
    this.element.requestSubmit()
  }

  filterAssetTypes(e) {
    const filterAssetTypes = get(e, 'detail.ids', [])

    let assetTypeIdInput = this.element.querySelector('input[name=asset_type_id]')
    assetTypeIdInput.value = join(uniq(filterAssetTypes), ',')
    this.element.requestSubmit()
  }

  refresh() {
    this.element.requestSubmit()
  }

}