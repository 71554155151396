import { Controller } from "@hotwired/stimulus"
import { Chart } from 'chart.js';
import { MatrixController, MatrixElement } from 'chartjs-chart-matrix';

Chart.register(MatrixController, MatrixElement);

export default class extends Controller {
  static targets = ["heatmap"]

  connect() {
    this.showHeatmap()
  }

  showHeatmap() {

    const endDate = moment(this.element.dataset.end)
    const startDate = moment(this.element.dataset.start)

    const heatData = JSON.parse(this.element.dataset.datapoints)

    const scales = {
      y: {
        type: 'time',
        offset: true,
        min: '1',
        max: '7',
        time: {
          unit: 'day',
          round: 'day',
          isoWeekday: 1,
          parser: 'i',
          displayFormats: {
            day: 'iiiiii'
          }
        },
        reverse: true,
        position: 'left',
        border: {
          display: false
        },
        ticks: {
          maxRotation: 0,
          autoSkip: true,
          padding: 5,
          font: {
            family: 'Quicksand',
            size: 9
          },
          callback: function(value, index, values) {
            const timestamp = value
            const date = new Date(timestamp)
            const day = date.getDay()

            if (day === 1) {
              return 'Mon'
            } else if (day === 3) {
              return 'Wed'
            } else if (day === 5) {
              return 'Fri'
            } else if (day === 0) {
              return 'Sun'
            } else {
              return ''
            }
          }
        },
        grid: {
          display: false,
          drawBorder: false,
          tickLength: 0
        }
      },
      x: {
        type: 'time',
        position: 'top',
        min: startDate.toDate(),
        max: endDate.toDate(),
        offset: true,
        time: {
          unit: 'week',
          round: 'week',
          isoWeekday: 1,
          displayFormats: {
            week: 'MMM'
          }
        },
        border: {
          display: false
        },
        ticks: {
          maxRotation: 0,
          autoSkip: true,
          font: {
            family: 'Quicksand',
            size: 9
          },
          padding: 5,
          callback: function(value, index, values) {
            const timestamp = value
            const currentDate = new Date(timestamp)

            const oneWeek = 604800000
            const previousDate = new Date(timestamp - oneWeek)

            const currentDateMonth = currentDate.getMonth()
            const previousDateMonth = previousDate.getMonth()

            const stringMonth = currentDate.toLocaleString('default', { month: 'short' })

            if (currentDateMonth !== previousDateMonth ) {
              return stringMonth.toUpperCase()
            } else {
              return
            }
          }
        },
        grid: {
          display: false,
          drawBorder: false,
          tickLength: 0,
        }
      }
    }

    const options = {
      animation: false,
      aspectRatio: 5.5,
      maintainAspectRatio: true,
      plugins: {
        legend: false,
        tooltip: {
          displayColors: false,
          bodyFont: {
            family: 'Quicksand'
          },
          callbacks: {
            title() {
              return ''
            },
            label(context) {
              const v = context.dataset.data[context.dataIndex]

              const date = new Date(v.d)
              const options = { day: 'numeric', month: 'short', year: 'numeric' };
              const formattedDate = date.toLocaleDateString('en-GB', options);

              return [v.v + " on " + formattedDate]
            }
          }
        },
      },
      scales: scales,
      layout: {
        padding: {
          top: 10
        }
      }
    }

    function getDataPointColor(c) {
      const value = c.dataset.data[c.dataIndex].v
      const alpha = (10 + value * 2) / 30

      if (value === 0) {
        return `rgb(219,223,224)`
      } else {
        return `rgba(84,82,146,${alpha})`
      }
    }

    new Chart(this.heatmapTarget, {
      type: 'matrix',
      data: {
        datasets: [{
          label: 'Task activity report matrix',
          data: heatData,
          backgroundColor: getDataPointColor,
          borderRadius: 1,
          borderWidth: 0,
          hoverBackgroundColor: getDataPointColor,
          hoverBorderColor: getDataPointColor,
          width(c) {
            const a = c.chart.chartArea || {}
            return ((a.right - a.left) / 53 - 1) - 1
          },
          height(c) {
            const a = c.chart.chartArea || {}
            return ((a.right - a.left) / 53 - 1) - 1
          }
        }]
      },
      options: options
    })
  }
}
