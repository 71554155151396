import { Controller } from "@hotwired/stimulus"
import { get } from 'lodash-es'

export default class extends Controller {
  static targets = ['actions', 'confirmationHint']

  onContractChange(e) {
    const contractId = get(e, 'detail.contract.id')
    if (contractId) {
      this.actionsTarget.classList.remove('hide')
      this.confirmationHintTarget.classList.remove('hide')
    } else {
      this.actionsTarget.classList.add('hide')
      this.confirmationHintTarget.classList.add('hide')
    }
  }

  reset(e) {
    e.preventDefault()

    window.dispatchEvent(new CustomEvent('contract-selected', { detail: { contract: { id: '', name: 'No contract selected' } } }))
  }
}