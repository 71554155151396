import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["datepicker", "clear"]

  connect() {
    // Set listeners for changes
    this.datepickerTarget.addEventListener('change', this.datepickerTargetChanged.bind(this));
    this.clearTarget.addEventListener('click', this.clearTargetClicked.bind(this));

    // Set default state of clear button
    this.updateClearButtonVisibility();
  }

  disconnect() {
    // Cleanup event listeners
    this.datepickerTarget.removeEventListener('change', this.datepickerTargetChanged);
    this.clearTarget.removeEventListener('click', this.clearTargetClicked);
  }

  // Triggered on datepicker changes
  datepickerTargetChanged() {
    this.updateClearButtonVisibility();
  }

  // Triggered on clear button click
  clearTargetClicked() {
    this.datepickerTarget._flatpickr.clear();
  }

  // Set visibility of the clear button
  updateClearButtonVisibility() {
    if (this.datepickerTarget.value) {
      this.clearTarget.classList.remove('hide');
    } else {
      this.clearTarget.classList.add('hide');
    }
  }
}
