import { Controller } from "stimulus"
import { DragDropUpload } from "../../lib/drag_drop_upload";

export default class extends Controller {

  static targets = ['fileName']

  connect() {
    new DragDropUpload(this.element, this.dragDropCallback.bind(this));
  }

  dragDropCallback(files) {
    // You cant not get an array, but we only want the first file
    var file = files[0];

    this.fileNameTarget.innerHTML = `${file.name} (${this.humanFileSize(file.size)})`;
  }

  humanFileSize(size) {
    var i = size == 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
    return +((size / Math.pow(1024, i)).toFixed(1)) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
  }
}
