import { Controller } from "@hotwired/stimulus"
import { get } from 'lodash-es'

export default class extends Controller {

  static targets = ["input", "display", "reset"]

  onSelect(e) {
    e.preventDefault()

    const contractJson = get(e, 'currentTarget.dataset.contract', '')
    if (contractJson.length == 0) {
      console.error('No contract data detected')
    } else {
      const contractData = JSON.parse(contractJson)
      this.fireCustomEvent(contractData)

      $(this.element).foundation('reveal', 'close')
    }
  }

  reset(e) {
    if (this.hasResetTarget) {
      this.resetTarget.classList.add('hide')
    }
    this.fireCustomEvent({ id: '', name: 'No contract selected' })
  }

  fireCustomEvent(contractData) {
    window.dispatchEvent(new CustomEvent('contract-selected', { detail: { contract: contractData } }))
  }
}
