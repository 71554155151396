import { Controller } from "@hotwired/stimulus"
import { find, get } from "lodash-es"

export default class extends Controller {

  static targets = ['entry', 'placeholder', 'contentFrame']

  static values = {
    refreshUrl: String
  }

  entryTargetConnected(target) {
    const status = get(target, 'dataset.status', false)
    if (status) {
      if (status == 'deleted') {
        this.fireAssetRemoved(target)
        this.refreshCurrentContentFrame()
      } else {
        // new item added to the dom after being selected
        // fire an event to trigger the select list to update
        window.dispatchEvent(new CustomEvent('affected-asset-added', { detail: { asset_instance_id: target.dataset.assetInstanceId } }))
        this.togglePlaceholder()

        // if status is 'new' do nothing        
        if (status == 'created') {
          this.resetContentFrame()
        }
      }
    }
  }

  removeEntry(e) {
    const removeTarget = find(this.entryTargets, (targetEl) => {
      return targetEl.contains(e.currentTarget)
    })

    if (removeTarget) {
      removeTarget.remove()
      this.fireAssetRemoved(removeTarget)
    }

    if (this.entryTargets.length == 0 && this.hasContentFrameTarget) {
      this.refreshCurrentContentFrame()
    } else {
      this.togglePlaceholder()
    }
  }

  togglePlaceholder() {
    if (!this.hasPlaceholderTarget) {
      return
    }

    if (this.entryTargets.length > 0) {
      this.placeholderTarget.classList.add('hide')
    } else {
      this.placeholderTarget.classList.remove('hide')
    }
  }

  resetContentFrame() {
    if (!this.hasContentFrameTarget) {
      console.error('no frame to refresh')
      return
    }
    if (!this.hasRefreshUrlValue) {
      console.error('no refresh url value')
      return
    }

    this.contentFrameTarget.setAttribute('src', '')
    this.contentFrameTarget.setAttribute('src', this.refreshUrlValue)
  }

  refreshCurrentContentFrame() {
    if (!this.hasContentFrameTarget) {
      console.error('no frame to refresh')
      return
    }
    const src = this.contentFrameTarget.getAttribute('src')
    this.contentFrameTarget.setAttribute('src', '')
    this.contentFrameTarget.setAttribute('src', src)
  }

  fireAssetRemoved(removeTarget) {
    const assetInstanceId = removeTarget.dataset.assetInstanceId
    window.dispatchEvent(new CustomEvent('affected-asset-removed', { detail: { asset_instance_id: assetInstanceId } }))
  }

}
