import SortableController from "../../shared/sortable_controller"

export default class extends SortableController {
  static values = { paging: Object, loaded: Array }
  static targets = ["pagination", "loading", "asyncContainer"]

  connect() {
    this.setPageLoaded(1)
    window.addEventListener("turbo:frame-render", this.loadPageFrame.bind(this))
  }

  loadPageFrame(event) {
    const evtTargetId = event.target.getAttribute('id')
    if (!evtTargetId.startsWith('metrics-page')) {
      return
    }

    this.setPageLoaded(event.target.dataset.page)
  }

  setPageLoaded(page) {
    let loadedPages = this.loadedValue || []
    loadedPages.push(page)
    this.loadedValue = loadedPages
    if (this.loadedValue.length == this.pagingValue.total_pages) {
      this.loadContent()
    }
  }

  loadContent() {
    // move all the loaded pages to the content div
    let container
    for (let page = 1; page <= this.pagingValue.total_pages; page++) {
      container = this.asyncContainerTarget.querySelector(`#metrics-page-${page}`)
      this.contentTarget.append(...container.childNodes)
    }
    this.loadingTarget.classList.add("hide")
  }
}
