import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["link"];

  scrollTo(event) {
    event.preventDefault();
    const linkTarget = this.element.getAttribute("href");
    const element = document.querySelector(linkTarget);

    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }
}