import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    eventName: String,
    dispatchOnDocument: Boolean,
    dispatchOnConnect: Boolean
  }

  initialize() {
    // Event name required
    this.eventName = this.eventNameValue
    this.dispatchOnDocument = this.dispatchOnDocumentValue || false
    this.dispatchOnConnect = this.dispatchOnConnectValue || false
  }

  connect() {
    if (this.dispatchOnConnect === true) {
      this.dispatchEvent()
    }
  }

  dispatchEvent() {
    if (this.dispatchOnDocument === true) {
      // Dispatch event on document
      document.dispatchEvent(new Event(this.eventName))
    } else {
      // Dispatch event on controller element
      this.element.dispatchEvent(new Event(this.eventName))
    }
  }
}
