import CheckedCountController from '../form/checked_count_controller';
import { filter } from 'lodash-es';

export default class extends CheckedCountController {
  static targets = [...CheckedCountController.targets, 'selector'];

  connect() {
    super.connect();
    this.updateTotal();
  }

  updateTotal() {
    let selectedOption;
    if (this.hasSelectorTarget) {
      selectedOption = this.selectorTarget.querySelector('input[type="radio"]:checked').value;
    } else {
      selectedOption = 'assets';
    }

    if (selectedOption === "category") {
      this.totalTarget.textContent = "Category selected";
    } else if (selectedOption === "assets") {
      const totalChecked = filter(this.checkboxTargets, { checked: true }).length;
      const totalOverall = this.checkboxTargets.length;
      this.totalTarget.textContent = `${totalChecked} of ${totalOverall} selected`;
    }
  }

  _toggle(e) {
    super._toggle(e);
    this.updateTotal();
  }
}
