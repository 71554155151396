import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["expiryDateClear", "expiryDateInput"]

  onChange(e) {
    if (!this.hasExpiryDateClearTarget) {
      return
    }

    if (e.currentTarget.value && e.currentTarget.value.length > 0) {
      this.expiryDateClearTarget.classList.remove('hide')
    } else {
      this.expiryDateClearTarget.classList.add('hide')
    }
  }

  clearExpiryDate(e) {
    if (!this.hasExpiryDateInputTarget) {
      return
    }

    this.expiryDateInputTarget.value = ''
    const datepickerEl = this.expiryDateInputTarget.nextElementSibling
    if (datepickerEl && datepickerEl.tagName.toLowerCase() == 'input' && datepickerEl.classList.contains('datepicker')) {
      datepickerEl.value = ''
    }
    this.expiryDateClearTarget.classList.add('hide')
  }
}