import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["matrix"]

  update(e) {
    if (!this.hasMatrixTarget) { return }

    this.matrixTarget.style.minHeight = this.matrixTarget.height + "px"
    this.matrixTarget.innerHTML = e.detail.matrix
  }
}
