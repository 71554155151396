import { Controller } from '@hotwired/stimulus'
import { throttle } from 'lodash-es'

export default class extends Controller {
  static targets = ['standard', 'sso', 'email', 'continueBtn']

  static values = {
    ssoLookupUrl: String
  }

  initialize() {
    this.onEmailChange = throttle(this.onEmailChange, 1000).bind(this)
  }

  connect() {
    if (this.hasEmailTarget && this.emailTarget.value.length > 0) {
      this.lookupLoginMode(this.emailTarget.value)
    }
  }

  onEmailChange(e) {
    const email = this.emailTarget.value
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    if (email.match(regex)) {
      this.lookupLoginMode(email)
    } else {
      if (email.trim().length == 0) {
        this.resetLogin()
      }
    }
  }

  lookupLoginMode(email) {
    const lookupUrl = new URL(this.ssoLookupUrlValue)
    lookupUrl.search = new URLSearchParams({ email: email }).toString()
    fetch(lookupUrl)
      .then((response) => {
        if (response.status === 204) {
          return Promise.resolve(null)
        } else if (!response.ok) {
          throw new Error('Login lookup failed')
        }
        return response.json()
      })
      .then((data) => {
        if (data && data.html) {
          this.showSsoLogin(data.html)
        } else {
          this.showStandardLogin()
        }
      })
      .catch((error) => {
        console.error(error)
        this.showStandardLogin()
      })
  }

  showStandardLogin() {
    this.continueBtnTarget.style.display = 'none';

    this.ssoTarget.innerHTML = ''
    this.ssoTarget.classList.add('hide')
    this.standardTarget.classList.remove('hide')
  }

  showSsoLogin(html) {
    this.continueBtnTarget.style.display = 'none';

    this.ssoTarget.innerHTML = html
    this.ssoTarget.classList.remove('hide')
    this.standardTarget.classList.add('hide')
  }

  resetLogin() {
    this.ssoTarget.classList.add('hide')
    this.standardTarget.classList.add('hide')
    this.continueBtnTarget.style.display = 'block';
  }

}
