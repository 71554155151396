import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    numReminders: Number
  }
  static targets = [
    "sendReminder",
    "sendAllContainer",
    "count"
  ]

  onSuccess(e) {
    this.setSuccessStatus(e.currentTarget)

    if (this.hasCountTarget) {
      this.numRemindersValue -= 1
      let countText
      if (this.numRemindersValue > 1) {
        countText = `${this.numRemindersValue} reminders`
      } else if (this.numRemindersValue > 0) {
        countText = `${this.numRemindersValue} reminder`
      } else {
        this.sendAllContainerTarget.classList.add("hide")
        return
      }

      this.countTargets.forEach(target => {
        target.innerHTML = countText
      })
    }
  }

  onError(e) {
    let error = document.createElement("div")
    error.classList.add("error-text", "font-size-0-8")
    error.innerHTML = "Reminder failed"
    e.currentTarget.parentElement.appendChild(error)
    setTimeout(() => error.remove(), 5000)
  }

  onSuccessAll(e) {
    let status = document.createElement("b")
    status.classList.add("success-text")
    status.innerHTML = "Sent just now"
    e.currentTarget.parentElement.appendChild(status)
    e.currentTarget.remove()

    this.sendReminderTargets.forEach(target => {
      this.setSuccessStatus(target)
    })
  }

  setSuccessStatus(el) {
    let status = document.createElement("span")
    status.classList.add("sub-info")
    status.innerHTML = "Sent just now"
    el.parentElement.appendChild(status)
    el.remove()
  }
}