import { Controller } from '@hotwired/stimulus'
import { get, filter, find, map, join } from 'lodash-es'

export default class extends Controller {

  static targets = ['input', 'entry', 'loading']
  static values = {
    url: String,
    enablePriority: { type: Boolean, default: true },
    enableX: { type: Boolean, default: false }
  }

  onSelect(event) {
    event.preventDefault()

    if (!this.hasInputTarget) {
      console.error('unable to process matrix condition selection - no input target found')
      return false
    }

    this.showLoading()

    const targetEntry = event.currentTarget
    const targetStatus = get(event, 'currentTarget.dataset.selected') !== 'true'
    const grade = get(targetEntry, 'dataset.grade')
    let priority = null
    if (this.enablePriorityValue) {
      priority = get(targetEntry, 'dataset.priority', '')
    }

    if (!grade && !priority) {
      // select none is the same as select all but more compact
      this.inputTarget.value = ''
    } else {
      const toggleAll = this.enablePriorityValue && !(grade && priority) // either a row or column-level selector
      if (toggleAll) {
        this.toggleAll(grade, priority, targetStatus)
      } else {
        this.toggleEntry(targetEntry, targetStatus)

        if (this.enableXValue && targetStatus) {
          this.toggleXEntry(grade)
        }
      }

      const selectedValues = map(this.selectedEntries(), (entry) => { return `${get(entry, 'dataset.grade')}${get(entry, 'dataset.priority', '')}` })

      this.inputTarget.value = join(selectedValues)
    }

    const form = this.inputTarget.closest('form')
    form.requestSubmit()
  }

  toggleAll(grade, priority, selectedStatus) {
    this.matrixEntries(grade, priority).forEach((entry) => {
      this.toggleEntry(entry, selectedStatus)
    })
  }

  toggleXEntry(grade) {
    if (!['C', 'D'].includes(grade)) {
      return
    }

    const xEntry = find(this.entryTargets, (entry) => { return get(entry, 'dataset.grade') == `${grade}X` })
    if (!xEntry) {
      console.error(`no x entry for ${grade}`)
      return
    }

    this.toggleEntry(xEntry, true)
  }

  toggleEntry(entry, selectedStatus) {
    if (selectedStatus) {
      entry.setAttribute('data-selected', 'true')
    } else {
      entry.removeAttribute('data-selected')
    }
  }

  selectedEntries() {
    return filter(this.entryTargets, (entry) => {
      return get(entry, 'dataset.selected', 'false') === 'true'
    })
  }

  matrixEntries(grade, priority) {
    let entryGrade, entryPriority
    return filter(this.entryTargets, (entry) => {
      entryGrade = get(entry, 'dataset.grade')
      if (grade && entryGrade != grade) {
        return false
      }

      if (this.enablePriorityValue) {
        entryPriority = get(entry, 'dataset.priority')
        if (priority && entryPriority != priority) {
          return false
        }
      }

      return true
    })
  }

  showLoading() {
    if (this.hasLoadingTarget) {
      this.loadingTarget.classList.remove('hide')
    }
  }

}