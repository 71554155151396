import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    assetName: String,
    taskName: String,
    frequency: String,
    fixedDateRenewals: Boolean,
    originalDescription: String
  }

  static targets = [
    "assetNameInput",
    "taskNameInput",
    "frequencyInput",
    "fixedDateRenewalsInput",
    "previewContainer",
    "preview"
  ]

  connect() {
    this.update()
  }

  update() {
    if (this.previewAvailable()) {
      this.previewTarget.textContent = this.previewTaskDescription()
      this.previewTarget.classList.remove('hide')
      if (this.hasPreviewContainerTarget) {
        this.previewContainerTarget.classList.remove('hide')
      }
    } else {
      if (this.originalDescriptionValue.length > 0) {
        this.previewTarget.classList.remove('hide')
        this.previewTarget.textContent = this.originalDescriptionValue
      } else {
        this.previewTarget.classList.add('hide')
        if (this.hasPreviewContainerTarget) {
          this.previewContainerTarget.classList.add('hide')
        }
      }
    }
  }

  previewAvailable() {
    return this.previewAssetName().length > 0 && this.previewTaskName().length > 0 && this.previewFrequency().length > 0
  }

  previewTaskDescription() {
    let taskDescription
    if (this.previewFixedDateRenewals()) {
      taskDescription = this.previewTaskName()
    } else {
      taskDescription = `${this.previewFrequency()} ${this.previewTaskName()}`
    }

    return `${this.previewAssetName()} ${taskDescription}`
  }

  previewAssetName() {
    let assetName = this.assetNameValue
    if (this.hasAssetNameInputTarget && this.assetNameInputTarget.selectedOptions) {
      assetName = this.assetNameInputTarget.selectedOptions[0].textContent
    }

    return assetName
  }

  previewTaskName() {
    let taskName = this.taskNameValue
    if (this.hasTaskNameInputTarget) {
      taskName = this.taskNameInputTarget.value
    }

    return taskName
  }

  previewFrequency() {
    let frequency = this.frequencyValue
    if (this.hasFrequencyInputTarget && this.frequencyInputTarget.selectedOptions) {
      frequency = this.frequencyInputTarget.selectedOptions[0].textContent
    }

    return frequency
  }

  previewFixedDateRenewals() {
    let fixedDateRenewals = this.fixedDateRenewalsValue
    if (this.hasFixedDateRenewalsInputTarget) {
      fixedDateRenewals = this.fixedDateRenewalsInputTarget.checked
    }

    return fixedDateRenewals
  }
}
