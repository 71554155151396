// Used in only one case - Bulk Uploads
// Would require a bit of work replacing this so for now have removed the icon causing the issue

import { Controller } from "stimulus";

export default class extends Controller {

  static targets = [
    'datepicker'
  ]
  connect() {
    this.setDatePicker();
  }

  setDatePicker() {
    const dp = $(this.datepickerTarget)

    dp.find('input.date').datepicker({
      buttonText: 'Change Date',
      buttonTextOnly: true,
      dateFormat: 'yy-mm-dd',
      showButtonPanel: false,
      showOn: "button",
      maxDate: "+0D",
      nextText: '',
      prevText: '',
      onSelect: (date) => {
        dp.find('.status').text(moment(date).format('dddd, MMMM Do YYYY'));
        dp.parent().addClass('selected');
      },
      beforeShow: () => {
        $('#ui-datepicker-div').addClass('iam-ui-skin');
      }
    });

    let date = $('input', dp).val();
    let formatted_date = 'Not set';

    if (date.length > 0) {
      formatted_date = moment(date).format('dddd, MMMM Do YYYY');
    }

    // Clear previous appended statuses
    dp.find('span.status').remove();

    dp.append('<span class="status">' + formatted_date + '</span>').click(() => {
      $('input', dp).datepicker('show');
    });
  }
}
