import { Controller } from "@hotwired/stimulus"
import { get } from "lodash-es"

export default class extends Controller {

  static targets = ["locationInput", "memberMessage"]

  checkEmail(event) {
    let emailInput = event.currentTarget

    this.resetInviteWarnings()
    if (!this.element.checkValidity() || emailInput.value.trim().length == 0) {
      return false
    }

    const _self = this
    this.postJson(`${window.location.href}/check_user`, { email: emailInput.value })
      .then(data => {
        if (data.status == 'member') {
          let link = document.createElement('a')
          link.setAttribute('href', data.link)
          link.classList.add('button', 'tiny', 'secondary', 'right', 'collapse')
          link.textContent = 'Manage user'

          // Already a member of this organisation
          _self.memberMessageTarget.textContent = 'This person already belongs to your organisation'
          _self.memberMessageTarget.appendChild(link)
          _self.memberMessageTarget.classList.remove('hide')
          _self.element.querySelector(".invitee-details").classList.add("hide")
          _self.element.querySelector("input[type=submit]").disabled = true

        } else if (data.status == 'new') {
          // Create new user and membership

        } else if (data.status == 'found') {
          // Existing user found, create new membership
          _self.element.querySelector(".invitee-name").classList.add("hide")
          _self.memberMessageTarget.textContent = "We already have a record of this person's first and last names in our database because they have used iAM before."
          _self.memberMessageTarget.classList.remove('hide')
        }
      })
      .catch(error => {
        console.error(error)
      })
  }

  assignAllLocations(event) {
    event.preventDefault()
    event.stopImmediatePropagation()

    this.locationInputTargets.forEach(el => {
      el.checked = true
    })
  }

  toggleRoleLocations(event) {
    let formData = new FormData(this.element)
    let labels = this.element.querySelectorAll('.invite_locations label, .add-to-all')
    let hints = this.element.querySelectorAll('.invite_locations .hint')
    if (formData.get('invite[role]') == 'owner') {
      labels.forEach(el => {
        el.style.display = 'none'
      })

      hints.forEach(el => {
        el.style.display = 'block'
      })
    } else {
      labels.forEach(el => {
        el.style.display = 'block'
      })

      hints.forEach(el => {
        el.style.display = 'none'
      })
    }
  }

  resetInviteWarnings() {
    this.element.querySelector("input[type=submit]").disabled = false
    this.element.querySelector(".invitee-name").classList.remove("hide")
    this.element.querySelector(".invitee-details").classList.remove("hide")
    this.memberMessageTarget.textContent = ""
    this.memberMessageTarget.classList.add('hide')
  }

  postJson(url, data) {
    return fetch(url, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        "X-CSRF-Token": this.csrfToken(),
        "Content-Type": "application/json"
      }
    }).then(response => {
      if (!response.ok) {
        throw new Error('Request failed')
      }
      return response.json()
    })
  }

  csrfToken() {
    const csrfToken = document.querySelector("[name='csrf-token']")

    return get(csrfToken, 'content')
  }

}