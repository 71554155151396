import { Controller } from "@hotwired/stimulus";
import { isFormDirty } from "stimulus-library";

export default class extends Controller {
  static targets = ['navigationForm', 'tasksForm'];

  initialize() {
    this.submitUnlessDirty = this.submitUnlessDirty.bind(this);
  }

  connect() {
    this.navigationFormTarget.querySelectorAll("input, select, textarea").forEach((el) => {
      el.dataset['value'] = el.value;
      el.addEventListener("change", this.submitUnlessDirty);
    });
  }

  disconnect() {
    this.navigationFormTarget.querySelectorAll("input, select, textarea").forEach((el) => el.removeEventListener("change", this.submitUnlessDirty));
  }

  submitUnlessDirty(event) {
    if (isFormDirty(this.tasksFormTarget)) {
      if (window.confirm('Are you sure? You have unsaved changes on this page.')) {
        this.navigationFormTarget.requestSubmit();
      } else {
        event.target.value = event.target.dataset['value'];
      }
    } else {
      this.navigationFormTarget.requestSubmit();
    }
  }

}
