import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["dateFrom", "dateTo", "allDates", "setDates"]

  connect() {
    this.observer = new MutationObserver(mutations => {
      this.updateDateRangeDisplay();
    });

    this.observeTargets();
    this.updateDateRangeDisplay();
  }

  disconnect() {
    this.observer.disconnect();
  }

  observeTargets() {
    this.observer.observe(this.dateFromTarget, { childList: true, subtree: true, characterData: true });
    this.observer.observe(this.dateToTarget, { childList: true, subtree: true, characterData: true });
  }

  updateDateRangeDisplay() {
    const dateFromContent = this.dateFromTarget.textContent.trim();
    const dateToContent = this.dateToTarget.textContent.trim();

    if (dateFromContent === '' && dateToContent === '') {
      this.allDatesTarget.classList.remove('hide');
      this.setDatesTarget.classList.add('hide');
    } else {
      this.allDatesTarget.classList.add('hide');
      this.setDatesTarget.classList.remove('hide');
    }
  }
}
