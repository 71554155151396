import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["linkedUploadsContainer"]

  removeLinkedUpload(event) {
    const nestedFields = this.linkedUploadsContainerTarget.querySelectorAll(".nested-fields")
    if (nestedFields.length <= 1) {
      // removing last file
      this.linkedUploadsContainerTarget.classList.add("hide")
    }
  }
}
