import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

  static targets = ["form", "ownerMessage", "memberLocations"]

  toggleRoleLocations(event) {
    let formData = new FormData(this.formTarget)
    if (formData.get('membership[role]') == 'owner') {
      this.ownerMessageTarget.classList.remove('hide')
      this.memberLocationsTarget.classList.add('hide')
    } else {
      this.ownerMessageTarget.classList.add('hide')
      this.memberLocationsTarget.classList.remove('hide')
    }
  }
}
