import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "count"
  ]

  connect() {
    let minsize = parseInt(this.element.dataset.minSize);
    let maxsize = parseInt(this.element.dataset.maxSize);
    let sizeRange = maxsize - minsize;

    let counts = this.countTargets;
    let values = counts.map(t => parseInt(t.dataset.count));

    let min = Math.min(...values);
    let max = Math.max(...values);

    let scale = min / max;

    counts.forEach(t => {
      let count = parseInt(t.dataset.count);
      let relative = (count * scale) / min;
      let size = minsize + (relative * sizeRange);

      t.style.width = size + 'px';
      t.style.height = size + 'px';
      t.style.marginTop = ((maxsize - size) / 2) + 'px';
      t.style.marginBottom = ((maxsize - size) / 2) + 'px';
    })
  }
}
