import { Controller } from "@hotwired/stimulus"
import { get } from 'lodash-es'


export default class extends Controller {

  static targets = ['frame', 'showOnSelect']
  static values = {
    url: String,
    current: String
  }

  refresh(e) {
    e.preventDefault()
    const targetEmail = get(e, 'detail.email')
    if (this.hasFrameTarget && this.hasUrlValue && targetEmail && targetEmail != this.currentValue) {
      this.showContent()
      let refreshUrl = new URL(location.origin + this.urlValue)
      let refreshParams = new URLSearchParams(refreshUrl.search)
      refreshParams.set('email', targetEmail)
      refreshUrl.search = refreshParams
      this.frameTarget.setAttribute('src', refreshUrl.toString())
      this.currentValue = targetEmail
    } else {
      this.hideContent()
    }
  }

  showContent() {
    this.showOnSelectTargets.forEach(element => {
      element.classList.remove('hide')
    })
  }

  hideContent() {
    this.showOnSelectTargets.forEach(element => {
      element.classList.add('hide')
    })
  }

}