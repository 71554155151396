import { Controller } from '@hotwired/stimulus'
import { throttle } from 'lodash-es'

export default class extends Controller {

  static targets = ['form']

  initialize() {
    this.selectTemplate = throttle(this.selectTemplate.bind(this), 1000)
  }

  connect() {
    if (this.hasFormTarget) {
      this.formTarget.classList.add('hide')
    }
  }

  selectTemplate(e) {
    this.element.querySelectorAll('label').forEach((elem) => elem.classList.remove('selected'))
    const radio = e.target
    if (radio) {
      radio.closest('label').classList.add('selected')

      const templateInput = this.formTarget.querySelector('input[name=template]')
      if (templateInput) {
        templateInput.value = radio.value
      }
      this.formTarget.classList.remove('hide')
    }
  }
}