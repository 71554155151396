import { Controller } from '@hotwired/stimulus'
import { get, forEach, keys } from 'lodash-es'

export default class extends Controller {

  static targets = ['usage', 'typeSelect', 'parentInput', 'usageSelect', 'submitButton']
  static values = {
    usageOptions: Object,
    parentTreePath: String
  }

  connect() {
    this.toggleFormUsage()
  }

  toggleFormUsage() {
    const typeValue = this.typeSelectTarget.value

    if (typeValue.length == 0 || typeValue == 'LocationZone') {
      this.hideUsage()
      this.hideParentInput()
      return
    }

    if (typeValue == 'Estates::Building') {
      this.hideParentInput()
    } else {
      this.showParentInput()
      this.triggerSelectorRefresh()
    }

    this.showUsage()
    this.setButtonsStatus()
  }

  setButtonsStatus() {
    if (this.submitEnabled()) {
      this.submitButtonTarget.disabled = false
    } else {
      this.submitButtonTarget.disabled = true
    }
  }

  cancelForm(e) {
    e.preventDefault()
    e.stopImmediatePropagation()

    this.typeSelectTarget.value = ''
    this.usageSelectTarget.value = ''
    this.usageTarget.classList.add('hide')
    this.usageSelectTarget.classList.add('hide')
  }

  buildUsageOptions() {
    const typeValue = get(this, 'typeSelectTarget.value')
    const selectedValue = get(this, 'usageSelectTarget.value')

    const optionValues = get(this, `usageOptionsValue[${typeValue}]`, [])
    this.usageSelectTarget.innerHTML = ''

    if (optionValues.length == 0) {
      return false
    }

    this.usageSelectTarget.appendChild(this.buildOption('Select usage', '', selectedValue == ''))

    if (keys(optionValues).length > 1) {
      // build group select options
      let groupEl
      forEach(optionValues, (values, group) => {
        groupEl = document.createElement('optgroup')
        groupEl.setAttribute('label', group)
        forEach(values, (opt) => {
          groupEl.appendChild(this.buildOption(opt, opt, opt == selectedValue))
        })
        this.usageSelectTarget.appendChild(groupEl)
      })
    } else {
      // no opt groups if there's only one
      forEach(optionValues, (values, _group) => {
        forEach(values, (opt) => {
          this.usageSelectTarget.appendChild(this.buildOption(opt, opt, opt == selectedValue))
        })
      })
    }
    return true
  }

  buildOption(label, value, selected) {
    const opt = document.createElement('option')
    opt.setAttribute('value', value)
    if (selected) {
      opt.setAttribute('selected', 'selected')
    }
    opt.textContent = label
    return opt
  }

  hideUsage() {
    if (this.hasUsageTarget) {
      this.usageTarget.classList.add('hide')
    }
    if (this.hasUsageSelectTarget) {
      this.usageSelectTarget.classList.add('hide')
    }
  }

  hideParentInput() {
    if (!this.hasParentInputTarget) {
      return
    }

    this.parentInputTarget.closest('.input').classList.add('hide')
    this.parentInputTarget.value = ''
  }

  showParentInput() {
    if (!this.hasParentInputTarget) {
      return
    }

    this.parentInputTarget.closest('.input').classList.remove('hide')
  }

  triggerSelectorRefresh() {
    if (this.hasParentTreePathValue) {
      let refreshParams = new URLSearchParams({ selector_type: 'parent', target_type: this.typeSelectTarget.value })
      window.dispatchEvent(new CustomEvent("refresh-location-zone-selector", {
        detail: {
          id: 'zone-selector-modal',
          refresh_url: `${this.parentTreePathValue}?${refreshParams.toString()}`
        }
      }))
    }
  }

  showUsage() {
    if (!this.hasUsageSelectTarget) {
      return
    }

    if (this.buildUsageOptions()) {
      this.usageSelectTarget.classList.remove('hide')
      if (this.hasUsageTarget) {
        this.usageTarget.classList.remove('hide')
      }
    } else {
      this.usageSelectTarget.classList.add('hide')
      if (this.hasUsageTarget) {
        this.usageTarget.classList.add('hide')
      }
    }
  }

  submitEnabled() {
    if (this.typeSelectTarget.value.length < 1) {
      return false
    }
    if (this.requiresUsage() && this.usageSelectTarget.value.length < 1) {
      return false
    }

    return true
  }

  requiresUsage() {
    const typeValue = this.typeSelectTarget.value
    if (typeValue == 'Estates::Room') {
      return true
    }

    return false
  }
}