import { Controller } from "stimulus"

export default class extends Controller {
  connect () {
    const event = new CustomEvent("add-tile-item", {
      detail: {
        newItem: this.element.dataset.id
      }
    });
    window.dispatchEvent(event);
  }
}