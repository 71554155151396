import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["actions", "toggle", "existingReason", "newReason"]

  connect() {
    this.update()
  }

  update(e) {
    this.setReason(this.toggleTarget.checked, (e !== undefined))
    this.setActions(!this.toggleTarget.checked)

    if (this.hasActionsTarget && this.actionsTarget.dataset.type == 'dropdown') {
      if (this.actionsTarget.selectedOptions[0].value.length == 0) {
        this.setToggle(true)
      } else {
        this.setToggle(false)
        this.setReason(false)
      }
    }
  }

  setActions(active) {
    if (!this.hasActionsTarget) { return }

    if (this.actionsTarget.dataset.type == 'hide') {
      this.actionsTarget.classList.toggle('hide', !active)
    } else {
      this.actionsTarget.disabled = !active

      if (!active) {
        this.actionsTarget.value = ""
      }
    }
  }

  setToggle(active) {
    if (!this.hasToggleTarget) { return }

    if (!active) {
      this.toggleTarget.checked = false
    }
  }

  setReason(active, clearExisting = false) {
    if (!this.hasNewReasonTarget) { return }

    if (active) {
      if (clearExisting) {
        this.newReasonTarget.classList.remove('hide')
      }
    } else {
      this.newReasonTarget.querySelector('textarea').value = ""
      this.newReasonTarget.classList.add('hide')
    }

    if (clearExisting && this.hasExistingReasonTarget) {
      this.existingReasonTarget.remove()
    }
  }
}
