import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  scroll(e) {
    let date = e.currentTarget.dataset.date;
    let el = document.getElementById(`date-${date}`)
    if (el) {
      el.scrollIntoView()
    }
  }
}
