import { Controller } from '@hotwired/stimulus'
import { get, clone, replace } from 'lodash-es'

export default class extends Controller {

  static values = {
    modalId: String,
    redirectPath: String
  }

  onSelect(e) {
    e.preventDefault()

    const locationJson = get(e, 'currentTarget.dataset.location', '')
    if (locationJson.length == 0) {
      console.error('No location data detected')
    } else {
      const locationData = JSON.parse(locationJson)
      if (this.hasRedirectPathValue) {
        this.redirectTo(locationData)
      } else {
        this.fireCustomEvent(locationData)
      }
    }

    $(this.element).foundation('reveal', 'close')
  }

  redirectTo(locationData) {
    let redirect = clone(this.redirectPathValue)
    const replacementId = get(locationData, 'scoped_id', '')
    if (locationData.scoped_id) {
      redirect = replace(redirect, ':location_id', replacementId)
    } else {
      redirect = replace(redirect, '/:location_id', replacementId)
    }
    let redirectUrl = new URL(location.origin + redirect)
    let redirectParams = new URLSearchParams(redirectUrl.search)
    if (redirectParams.has('location_id')) {
      redirectParams.set('location_id', replacementId)
      redirectUrl.search = redirectParams
    }
    window.location.href = redirectUrl.toString()
  }

  fireCustomEvent(locationData) {
    window.dispatchEvent(new CustomEvent('location-selected', { detail: { modal_id: this.element.getAttribute('id'), location: locationData } }))
  }
}