import { Controller } from '@hotwired/stimulus'
import { get } from 'lodash-es'

export default class extends Controller {

  remove(e) {
    const id = get(e, 'currentTarget.dataset.id')
    const targetFilter = get(e, 'currentTarget.dataset.filter')
    const el = this.element.querySelector(`[data-filter-type="${targetFilter}"][data-filter-value="${id}"]`)
    if (!el) {
      console.error(`Target filter element not found: #${targetFilter}-${id}`)
      debugger
      return false
    }
    el.click();
  }
}
