import { Controller } from '@hotwired/stimulus'
import { get } from 'lodash-es'

export default class extends Controller {

  static targets = ['sort', 'direction']

  zoneSelected(event) {
    const baInput = this.element.querySelector('input[name=building_asset_id]')

    if (baInput) {
      baInput.value = get(event, 'detail.zone.id')
      this.element.requestSubmit()
    } else {
      console.error('unable to process zone selection - no input target detected')
    }
  }
}