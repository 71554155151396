import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    collapse: {type: Boolean, default: false}
  }

  toggle() {
    const sidebar = this.element.nextElementSibling
    const content = this.element.previousElementSibling
    this.collapseValue = !this.collapseValue
    if (this.collapseValue) {
      this.collapse(sidebar, content)
    } else {
      this.expand(sidebar, content)
    }
  }

  expand(sidebar, content) {
    sidebar.classList.remove("hide")
    content.classList.remove("medium-12")
    content.classList.add("medium-9")
    this.element.classList.remove("collapse")
  }

  collapse(sidebar, content) {
    sidebar.classList.add("hide")
    content.classList.remove("medium-9")
    content.classList.add("medium-12")
    this.element.classList.add("collapse")

  }
}
