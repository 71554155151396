import { Controller } from '@hotwired/stimulus';

// Listen for changes to innerHTML text and attempt to auto-format as a date.
export default class extends Controller {
  static values = { format: String }

  connect() {
    const config = {
      characterData: true,
      childList: true,
    };

    this.observer = new MutationObserver((mutationsList, observer) => {
      // The string that was set inside
      const date = Date.parse(mutationsList.filter((record) => record.type == 'childList').pop().target.innerHTML);

      // TODO: date-fns

      // What it needs to be replaced with
      const formattedDate = moment(date).format(this.formatValue)

      // If the change created a mutation record we would get stuck
      // in a loop
      observer.disconnect();
      this.element.innerHTML = formattedDate;
      observer.observe(this.element, config);
    });

    this.observer.observe(this.element, config);
  }

  disconnect() {
    this.observer.disconnect();
  }
}
