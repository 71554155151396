import { Controller } from '@hotwired/stimulus'
import { get } from 'lodash-es'

export default class extends Controller {

  static targets = ['usage']

  onUtilitySelect(e) {
    if (this.hasUsageTarget) {
      const units = get(e.currentTarget, 'selectedOptions[0].dataset.units', '')

      const label = this.usageTarget.querySelector('label')

      if (label) {
        const labelText = (units.length > 0) ? `Usage (${units})` : 'Usage'
        label.textContent = labelText
      }
    }
  }

}