import { Controller } from "@hotwired/stimulus"
import { get } from 'lodash-es'

export default class extends Controller {
  static targets = ['form', 'location']

  connect() {
    window.addEventListener('location-selected', (e) => {
      const id = get(e, 'detail.location.scoped_id', 'all')
      this.locationTarget.value = id

      this.formTarget.requestSubmit()
    })
  }
}
