import { Controller } from "@hotwired/stimulus"
import { find } from "lodash-es"

export default class extends Controller {
  static targets = ["selectedEmail"]
  static values = {
    emails: Array,
    category: String,
    location: String
  }

  selectLocation(event) {
    event.preventDefault()

    this.locationValue = event.currentTarget.value
    this.setCurrentEmail()
  }

  selectCategory(event) {
    event.preventDefault()

    this.categoryValue = event.currentTarget.value
    this.setCurrentEmail()
  }

  setCurrentEmail() {
    const emailEntry = find(this.emailsValue, { location: this.locationValue, category: this.categoryValue })
    this.selectedEmailTarget.dataset.clipboardCopyableValue = emailEntry.email
    const emailLink = this.selectedEmailTarget.querySelector('a.email')
    emailLink.textContent = emailEntry.email
    emailLink.href = `mailto:${emailEntry.email}`
  }

}