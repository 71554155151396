// For use on any Turbo Drive powered form following the :search_form -> :search_results pattern
// Shows a loading spinner for cases when frame src is not being used and while searching takes place
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["spinner", "form", "results"]

  connect() {
    this.element.addEventListener('turbo:submit-start', this.show.bind(this));
    this.element.addEventListener('turbo:submit-end', this.hide.bind(this));
  }

  show() {
    this.spinnerTarget.classList.remove('hide');
    this.resultsTarget.classList.add('hide');
  }

  hide() {
    this.spinnerTarget.classList.add('hide');
    this.resultsTarget.classList.remove('hide');
  }
}
