import AutoSubmitFormController from '../../shared/auto_submit_form_controller'
import { split, get } from 'lodash-es'

export default class extends AutoSubmitFormController {

  static targets = ['categoryTypeInput', 'locationInput']

  handler(e) {
    this.parseCategoryTypeSelection()
    this.setFormAction()

    // disable any empty or hidden previously expanded, now hidden elements
    this.element.querySelectorAll('input.expandable[style*="display:none"]').forEach(elem => elem.setAttribute('disabled', 'disabled'))

    super.handler(e)
  }

  parseCategoryTypeSelection() {
    if (!this.hasCategoryTypeInputTarget) {
      return false
    }

    let categoryId, assetTypeId
    const splits = split(this.categoryTypeInputTarget.value, ':', 2)
    categoryId = get(splits, '[0]', '')
    assetTypeId = get(splits, '[1]', '')

    const categoryInput = this.element.querySelector('input[name="category_id"]')
    if (categoryInput) {
      categoryInput.value = categoryId
    }

    const typeInput = this.element.querySelector('input[name="asset_type_id"]')
    if (typeInput) {
      typeInput.value = assetTypeId
    }
  }

  setFormAction() {
    if (!this.hasLocationInputTarget) {
      return false
    }

    let locationId = this.locationInputTarget.value
    let formPath = this.element.getAttribute('action')
    let filterPath = formPath.match(/.*\/asset-register/)[0]
    if (locationId != '') {
      filterPath += '/' + locationId
    }

    if (formPath.endsWith('/disposed')) {
      filterPath += "/disposed"
    }

    this.element.setAttribute('action', filterPath)
  }

}
