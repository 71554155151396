import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['table', 'expandedInput', 'sidebar', 'content', 'toggleButton']

  initialize() {

    this.nextState = {
      'expanded': 'retracted',
      'retracted': 'expanded',
    }
  }

  connect() {
    const currentVal = this.hasExpandedInputTarget ? this.expandedInputTarget.value : ''
    this.state = currentVal === '' ? 'retracted' : currentVal
    this.updateVisibility()
  }

  updateVisibility() {
    if (this.hasTableTarget) {
      this.tableTarget.classList.add(this.state)
    }
    this.updateToggle()

    // If targets have been set for the content div and the sidebar div, hide the sidebar
    // and expand the content when the table is expanded.
    if (this.hasSidebarTarget && this.hasContentTarget && this.state === 'retracted') {
      this.sidebarTarget.classList.remove('hide')
      this.contentTarget.classList.add('medium-9')
    } else if (this.hasSidebarTarget && this.hasContentTarget) {
      this.sidebarTarget.classList.add('hide')
      this.contentTarget.classList.remove('medium-9')
    }
  }

  updateToggle() {
    if (!this.hasToggleButtonTarget) {
      return
    }

    let span = this.toggleButtonTarget.querySelector('span')
    let icon = this.toggleButtonTarget.querySelector('i.fa')
    let labelText
    if (this.state in span.dataset) {
      labelText = span.dataset[this.state]
    }
    if (this.state === 'expanded') {
      labelText ||= 'Show less'
      icon.classList.remove('fa-chevron-right')
      icon.classList.add('fa-chevron-left')
      this.toggleButtonTarget.insertBefore(icon, span)
    } else {
      labelText ||= 'Show more'
      icon.classList.remove('fa-chevron-left')
      icon.classList.add('fa-chevron-right')
      this.toggleButtonTarget.insertBefore(span, icon)
    }
    span.textContent = labelText
  }

  toggle(e) {
    e.stopImmediatePropagation()
    e.preventDefault()

    this.state = this.nextState[this.state]

    if (this.hasTableTarget) {
      // This only works with 2 states
      this.tableTarget.classList.remove(this.nextState[this.state])
      this.tableTarget.classList.add(this.state)
    }

    if (this.hasExpandedInputTarget) {
      this.expandedInputTarget.value = this.state
    }

    this.updateVisibility()
  }
}