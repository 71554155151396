import { Controller } from '@hotwired/stimulus'
import { find, get } from 'lodash-es'

export default class extends Controller {
  static outlets = ['insights--dataset-filter']

  remove(e) {
    const targetFilter = get(e, 'currentTarget.dataset.filter')
    if (!targetFilter) {
      console.error('Invalid target filter')
      return false
    }
    if (!this.hasInsightsDatasetFilterOutlet) {
      console.error('Unable to process filter removal')
      return false
    }

    const filterOutlet = find(this.insightsDatasetFilterOutlets, (outlet) => {
      return get(outlet, 'element.dataset.filterType') == targetFilter
    })
    if (!filterOutlet) {
      console.error(`Target filter not found: ${targetFilter}`)
      return false
    }

    filterOutlet.remove(e)
  }
}
