import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "titleHeader", "titleField", "descriptionField", "metadata",
    "removedPhotosStatus", "deletePhotoLink",
    "editButton", "formControls"
  ]

  reset(event) {
    event.preventDefault()
    event.stopPropagation()

    this.element.reset()
    this.metadataTarget.classList.remove("hide")
    this.formControlsTarget.classList.add("hide")
    this.editButtonTarget.style.display = ""

    this.titleFieldTarget.classList.add("hide")
    this.titleHeaderTarget.classList.remove("hide")

    this.deletePhotoLinkTargets.forEach(target => {
      target.classList.add("hide")
      target.closest(".asset-photo").classList.remove("hide")
    })
    this.updateDeletedPhotoStatus()

    this.descriptionFieldTarget.classList.add("hide")
    this.element.querySelectorAll(".condition-description").forEach(function (elem) { elem.classList.remove("hide") })
    window.dispatchEvent(new CustomEvent("enable-file-uploads"))
  }

  showForm(event) {
    event.preventDefault()
    event.stopPropagation()

    window.dispatchEvent(new CustomEvent("disable-file-uploads"))
    this.metadataTarget.classList.add("hide")

    this.titleFieldTarget.classList.remove("hide")
    this.titleHeaderTarget.classList.add("hide")

    this.descriptionFieldTarget.classList.remove("hide")
    this.element.querySelectorAll(".condition-description").forEach(function (elem) { elem.classList.add("hide") })

    this.deletePhotoLinkTargets.forEach(target => target.classList.remove("hide"))

    this.editButtonTarget.style.display = "none"
    this.formControlsTarget.classList.remove("hide")
  }

  removePhoto(event) {
    event.preventDefault()
    event.stopPropagation()

    let wrapper = event.target.closest(".asset-photo")
    wrapper.querySelector("input[name*='_destroy']").value = 1
    wrapper.classList.add("hide")
    this.updateDeletedPhotoStatus()
  }

  updateDeletedPhotoStatus() {
    const numDeleted = this.numDeletedPhotos()
    if (numDeleted > 0) {
      let message = (numDeleted == 1) ? "1 photo to be deleted" : `${numDeleted} photos to be deleted`
      this.removedPhotosStatusTarget.textContent = message
      this.removedPhotosStatusTarget.classList.remove("hide")
    } else {
      this.removedPhotosStatusTarget.textContent = ""
      this.removedPhotosStatusTarget.classList.add("hide")
    }
  }

  numDeletedPhotos() {
    return this.element.querySelectorAll('.asset-photo.hide').length
  }
}
