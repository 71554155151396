import { Controller } from '@hotwired/stimulus'
import { has, get } from 'lodash-es'

/*
 * Filters a list with an AJAX request, processing a JSON response with multiple HTML fragments to render the list
 * along with refreshed and updated filters.
 *
 * The text filter is a uniquely named target which the controller manipulates on the page, rather than refreshing
 * it with a new fragment. The other filters are in the filterTargets collection.
 */
export default class FilteredListController extends Controller {
  static targets = ['filters', 'filter', 'list', 'textFilter', 'download']
  static values = { url: String }

  update(e) {
    if (!this.hasFilterTarget) { return }

    this.disableFilters()

    let params = new URLSearchParams()

    if ('filters' in e.currentTarget.dataset) {
      // a link was clicked, process as if filter was changed
      e.preventDefault()
      const filters = JSON.parse(e.currentTarget.dataset.filters)
      for (const [name, value] of Object.entries(filters)) {
        params.set(name, value)
      }
    } else {
      // filter was changed
      this.filterTargets.forEach(filter => {
        params.set(filter.name, filter.value);
      });
      params.set('text', this.textFilterTarget.value);
    }

    this.fetchResponse(params)
  }

  clear(e) {
    this.textFilterTarget.value = null;
    this.fetchResponse();
  }

  page(e) {
    e.preventDefault()
    const link = e.target.closest('a')
    const url = new URL(link.href)
    let params = new URLSearchParams(url.search)

    this.fetchResponse(params)
  }

  disableFilters() {
    this.filterTargets.forEach(element => {
      element.disabled = true
    })
  }

  fetchResponse(params) {
    fetch(this.urlValue + "?" + params)
      .then(response => response.json())
      .then(data => this.processResponse(data))
  }

  processResponse(data) {
    this.textFilterTarget.enabled = true;
    this.filtersTarget.innerHTML = data.filters;
    this.listTarget.innerHTML = data.list;

    if (this.hasDownloadTarget && has(data, 'total')) {
      const total = parseInt(get(data, 'total', '0'))
      if (total > 0) {
        this.downloadTarget.classList.remove('hide')
      } else {
        this.downloadTarget.classList.add('hide')
      }
    }

    // update URL with selected filters
    history.replaceState({}, '', data.url)

    // notify custom controllers (eg. risk_register)
    let event = new CustomEvent('filteredList:updated', { detail: data })
    this.element.dispatchEvent(event)
  }
}
