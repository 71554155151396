import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ["filters", "toggle", "status"]

  static values = {
    open: Boolean
  }

  connect() {
    if (this.openValue == true) {
      this.show();
    }
  }

  toggle(e) {
    e.preventDefault();
    if (this.openValue == true) {
      this.hide_and_reset();
    } else {
      this.show();
    }
  }

  hide_and_reset() {
    this.openValue = false
    this.filtersTarget.classList.add("hide")
    this.toggleTarget.classList.remove("hide")
    window.history.replaceState(null, '', window.location.pathname);
    this.filtersTarget.querySelectorAll("select").forEach(select => {
      select.selectedIndex = 0;
    });
    this.submit();
  }

  show() {
    this.openValue = true
    this.filtersTarget.classList.remove("hide")
    this.toggleTarget.classList.add("hide")
  }

  submit() {
    const selectedOption = this.statusTarget.options[this.statusTarget.selectedIndex];
    const context = selectedOption.getAttribute('data-context');

    let newUrl = new URL(window.location.href);
    let pathname = newUrl.pathname;

    if (context === 'closed') {
      if (!pathname.endsWith('/closed')) {
        newUrl.pathname = pathname + '/closed';
      }
    } else {
      if (pathname.endsWith('/closed')) {
        newUrl.pathname = pathname.replace(/\/closed$/, '');
      }
    }

    window.history.replaceState(null, '', newUrl.toString());
    this.element.requestSubmit();
  }
}
