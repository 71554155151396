import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["datepicker", "form", "template"]

  connect() {
    this.datepickerTarget.addEventListener('change', this.handleDateChange.bind(this));
  }

  disconnect() {
    this.datepickerTarget.removeEventListener('change', this.handleDateChange);
  }

  handleDateChange() {
    this.maybeRefreshDownloadButton();
  }

  maybeRefreshDownloadButton() {
    const turboFrame = this.formTarget.querySelector('turbo-frame');

    if (turboFrame && turboFrame.id !== 'scorecard_download') {
      while (turboFrame.firstChild) {
        turboFrame.removeChild(turboFrame.firstChild);
      }
      turboFrame.id = 'scorecard_download';

      const templateContent = this.templateTarget.innerHTML;
      turboFrame.innerHTML = templateContent;
    }
  }
}
