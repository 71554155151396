import { Controller } from '@hotwired/stimulus'
import { find, forEach, get, includes } from 'lodash-es'

export default class extends Controller {

  static values = { refreshUrl: String }
  static targets = ['frame', 'groupIdsInput', 'locationIdsInput', 'groupErrorBox', 'locationErrorBox']

  connect() {
    this.refreshSelectedGroups()
  }

  refreshSelectedGroups(e) {
    if (!this.hasFrameTarget) {
      return
    }

    let refreshUrl = new URL(location.origin + this.refreshUrlValue)
    let refreshParams = new URLSearchParams(refreshUrl.search)
    refreshParams.set('group_ids', JSON.stringify(this.selectedGroupIds()))
    refreshParams.set('location_ids', JSON.stringify(this.selectedLocationIds()))
    refreshUrl.search = refreshParams
    this.frameTarget.setAttribute('src', refreshUrl.toString())
  }

  selectedGroupIds() {
    return this.processSelectValues(this.groupIdsInputTarget, this.groupIdsInputTargets)
  }

  selectedLocationIds() {
    if (this.hasLocationIdsInputTarget) {
      return this.processSelectValues(this.locationIdsInputTarget, this.locationIdsInputTargets)
    } else {
      return []
    }
  }

  processSelectValues(rootInput, allInputs) {
    let selectAll = false
    if (rootInput.checked) {
      selectAll = true
    }

    const selected = []

    forEach(allInputs, function (opt) {
      if ((opt.checked || selectAll) && opt.value != '') {
        selected.push(opt.value)
        if (selectAll) {
          opt.selected = false
        }
      }
    })

    return selected
  }

  removeGroup(e) {
    const groupId = parseInt(e.currentTarget.getAttribute("data-value"))
    const selectedElement = this.groupIdsInputTargets.find((opt) => parseInt(opt.value) == groupId)
    if (selectedElement) {
      selectedElement.parentElement.click()
    }

    return this.refreshSelectedGroups()
  }

  toggleGroup(e) {
    // Check if toggling root input (ie 'All courses') or input associated with an item id (course, category, group)
    const input = e.currentTarget.querySelector('input')
    const type = input.getAttribute("data-type")
    input.checked = !input.checked
    if (type == "group" && input != this.groupIdsInputTarget) {
      this.groupIdsInputTarget.checked = false
    } else if (type == "location" && input != this.locationIdsInputTarget) {
      this.locationIdsInputTarget.checked = false
    } else {
      this.resetGroupOptions(input)
    }

    this.removeErrorBox(type)
    this.refreshSelectedGroups(e)
  }

  resetGroupOptions(input) {
    const type = input.getAttribute("data-type")
    let filteredOpts
    if (type == "group") {
      filteredOpts = this.groupIdsInputTargets.filter((opt) => opt != this.groupIdsInputTarget)
    } else if (type == "location") {
      filteredOpts = this.locationIdsInputTargets.filter((opt) => opt != this.locationIdsInputTarget)
    }
    filteredOpts.forEach(opt => {
      opt.checked = input.checked
    });
  }

  removeErrorBox(type) {
    if (type == "group" && this.hasGroupErrorBoxTarget) {
      this.groupErrorBoxTarget.remove()
    } else if (type == "location" && this.hasLocationErrorBoxTarget) {
      this.locationErrorBoxTarget.remove()
    }
  }
}
