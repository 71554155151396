import { Controller } from "@hotwired/stimulus"
import Dropzone from "dropzone"

Dropzone.autoDiscover = false

export default class extends Controller {
  static values = {
    url: String,
    numPhotos: Number
  }

  static targets = [
    "carousel", "dropzone", "previews", "prompt", "error", "previewTemplate"
  ]

  disableDropzone() {
    if (this.dropzone) {
      this.dropzone.disable()
    }
    this.dropzoneTarget.classList.add('hide')
  }

  enableDropzone() {
    if (this.dropzone) {
      this.dropzone.enable()
    }
    this.dropzoneTarget.classList.remove('hide')
    this.countNumPhotos()
  }

  reloadPhotos(_event, response) {
    this.carouselTarget.innerHTML = response.html_data
    $(document).foundation('clearing', 'reflow')
    this.countNumPhotos()
  }

  countNumPhotos() {
    this.numPhotosValue = this.carouselTarget.querySelectorAll('.asset-photo:not(.hide)').length
  }

  showMaxFilesError() {
    if (this.hasErrorTarget) {
      this.errorTarget.classList.remove('hide')
    }
  }

  numPhotosValueChanged() {
    if (this.numPhotosValue < 5) {
      this.dropzoneTarget.classList.remove('hide')
    } else {
      this.dropzoneTarget.classList.add('hide')
    }
    this.initDropzone()
  }

  toggleDrag() {
    this.dropzoneTarget.classList.toggle('dz-hovering')
  }

  initDropzone() {
    if (this.dropzoneTarget.dropzone) {
      return
    }

    const _self = this
    let opts = {
      url: _self.urlValue,
      method: 'PATCH',
      paramName: (_n) => {
        return 'entry[photos][]'
      },
      maxFiles: 5 - (_self.numPhotosValue),
      parallelUploads: 1,
      uploadMultiple: true,
      acceptedFiles: "image/jpeg, image/png",
      previewsContainer: _self.previewsTarget,
      thumbnailHeight: 60,
      thumbnailWidth: 60,
      headers: {
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').content
      }
    }
    if (_self.hasPreviewTemplateTarget) {
      opts.previewTemplate = _self.previewTemplateTarget.innerHTML
    }

    const dropzone = new Dropzone(this.dropzoneTarget, opts)
    dropzone
      .on("maxfilesexceeded", _self.showMaxFilesError.bind(_self))
      .on("successmultiple", _self.reloadPhotos.bind(_self))
      .on("complete", (file) => dropzone.removeFile(file))
      .on("dragenter", _self.toggleDrag.bind(_self))
      .on("dragleave", _self.toggleDrag.bind(_self))
      .on("drop", _self.toggleDrag.bind(_self))
    this.dropzone = dropzone
  }
}
