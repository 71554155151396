import { Controller } from "@hotwired/stimulus"
import { DirectUpload } from '@rails/activestorage'
import { get } from "lodash-es"
import { humanizeFilename } from "../../../lib/file_utils";

export default class extends Controller {

  static values = {
    state: String,
    fieldId: String
  }

  static targets = [
    'uploadField',
    'uploadFileName',
    'documentId',
    'expandOptionsControl',
    'expandOptionsControlButton',
    'expandOptionsText',
    'expandOptions',
    'publicationDate',
    'reviewFrequency'
  ]

  initialize() {
    this.file = null;
    this.uploading = false;
    this.locationId = null;
    this.folderId = null;
  }

  connect() {
    if (this.stateValue == 'new' && this.hasExpandOptionsTextTarget) {
      this.updateExpandOptionsText();
    }

    this.addEventListeners();
    this.dispatchEvents();
  }
  addEventListeners() {
    document.addEventListener(`uploadEvent_${this.element.id}`, this.performUpload.bind(this));
  }
  dispatchEvents() {
    document.dispatchEvent(new CustomEvent(`uploadFieldReady`, { bubbles: true }));
    document.dispatchEvent(new CustomEvent(`uploadFieldReady_${this.element.id}`, { bubbles: true }));
  }
  performUpload(event) {
    this.uploading = true;
    document.dispatchEvent(new CustomEvent('uploadStarted', { bubbles: true }));

    this.file = event.detail.file;
    this.locationId = event.detail.locationId;
    this.folderId = event.detail.folderId;

    const uploader = new DirectUpload(this.file, this.element.dataset.directUploadUrl, this);

    uploader.create((error, blob) => {
      if (error) {
        // Feedback for failure occurs at parent level
        const uploadErrorEvent = new CustomEvent('uploadErrorEvent');
        document.dispatchEvent(uploadErrorEvent);

      } else {
        // Signed blob id received, need to create document/version objects
        this.createUpload(blob.signed_id);
      }
    })
  }

  createUpload(blobSignedId) {
    const url = this.element.dataset.createUploadUrl;
    const data = {
      name: humanizeFilename(this.file.name),
      blob_signed_id: blobSignedId,
      field_id: this.fieldIdValue,
      location_id: this.locationId,
      folder_id: this.folderId,
    };

    fetch(url, {
      method: 'POST',
      headers: {
        "X-CSRF-Token": this.csrfToken(),
        'Accept': 'text/vnd.turbo-stream.html',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data)
    }).then (response => response.text())
    .then(html => {
      Turbo.renderStreamMessage(html);
      document.dispatchEvent(new CustomEvent('uploadFinished', { bubbles: true }));
    });
  }

  csrfToken() {
    const csrfToken = document.querySelector("[name='csrf-token']");

    return get(csrfToken, 'content');
  }

  updateExpandOptionsText() {
    const publicationDate = this.publicationDateTarget.value;
    const reviewFrequency = this.reviewFrequencyTarget.value;

    const parsedDate = new Date(publicationDate);
    let formattedDate = `${this.getDayOrdinal(parsedDate.getUTCDate())} ${parsedDate.toLocaleString('en-GB', { month: 'long' })} ${parsedDate.getUTCFullYear()}`;

    const outputString = `Published ${formattedDate}, due for review in ${reviewFrequency} months`;

    this.expandOptionsTextTarget.textContent = outputString;
    this.showItem(this.expandOptionsControlButtonTarget);
  }

  getDayOrdinal(day) {
    let j = day % 10, k = day % 100;
    if (j == 1 && k != 11) {
      return day + "st";
    }
    if (j == 2 && k != 12) {
      return day + "nd";
    }
    if (j == 3 && k != 13) {
      return day + "rd";
    }
    return day + "th";
  }

  expandOptions(e) {
    e.preventDefault();

    this.hideItem(this.expandOptionsControlTarget);
    this.showItem(this.expandOptionsTarget);
  }

  showItem(item) {
    item.classList.remove('hide');
  }

  hideItem(item) {
    item.classList.add('hide');
  }
}
