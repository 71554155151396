import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["ownership", "allowOpenResponses", "disallowOpenResponses"];

  connect() {
    this.setOpenFormState();
  }

  ownershipChanged() {
    this.setOpenFormState();
  }

  setOpenFormState() {
    const ownershipValue = this.ownershipTarget.value.trim();

    if (ownershipValue == '') {
      this.allowOpenResponsesTarget.style.display = 'none';
      this.disallowOpenResponsesTarget.style.display = 'block';
    } else {
      this.allowOpenResponsesTarget.style.display = 'block';
      this.disallowOpenResponsesTarget.style.display = 'none';
    }
  }
}
