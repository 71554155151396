import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect () {
    const iframe = this.element.querySelector('iframe.video')
    if (iframe) {
      const wrapperDiv = document.createElement('div')
      wrapperDiv.className = 'embedded-video'
      iframe.setAttribute('width', '100%')
      iframe.setAttribute('height', '100%')
      iframe.parentNode.insertBefore(wrapperDiv, iframe)
      wrapperDiv.appendChild(iframe)
    }
  }
}