import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['selector', 'totalSection', 'categorySection', 'category', 'assetSection', 'selectAllAssets', 'asset'];

  connect() {
    this.parentFormController = this.application.getControllerForElementAndIdentifier(document.getElementById('helpdesk-categories-form'), 'helpdesk--categories-form');
    this.addEventListeners();
    this.updateSectionBasedOnFormState();
  }

  addEventListeners() {
    document.addEventListener('helpdeskCategories:change', this.updateSectionBasedOnFormState.bind(this));
  }

  select() {
    this.updateSectionBasedOnFormState();
  }

  updateSectionBasedOnFormState() {
    const showSections = this.parentFormController.enableAssetsChecked() && !this.parentFormController.selectAllChecked();
    this.show(this.totalSectionTarget, showSections);

    if (showSections) {
      this.updateSelectorState(true);
      const selectedOption = this.getSelectedOption();

      this.maybeShowSections(selectedOption);
      this.maybeEnableInputs(selectedOption);
    } else {
      this.updateSelectorState(false);
      this.disableInputs(['category', 'selectAllAssets', 'asset']);
    }
  }

  updateSelectorState(isEnabled) {
    if (this.hasSelectorTarget) {
      this.selectorTarget.querySelectorAll('input').forEach(input => input.disabled = !isEnabled);
    }
  }

  maybeShowSections(selectedOption) {
    if (this.hasCategorySectionTarget) {
      this.show(this.categorySectionTarget, selectedOption === 'category');
    }
    this.show(this.assetSectionTarget, selectedOption === 'assets');
    this.show(this.totalSectionTarget, true, true);
  }

  maybeEnableInputs(selectedOption) {
    const isCategory = selectedOption === 'category';
    const isAssets = selectedOption === 'assets';

    if (this.hasCategoryTarget) {
      this.categoryTarget.disabled = !isCategory;
    }

    if (this.hasSelectAllAssetsTarget) {
      this.selectAllAssetsTarget.disabled = !isAssets;
    }

    this.assetTargets.forEach(asset => asset.disabled = !isAssets);
  }

  disableInputs(targets) {
    targets.forEach(target => {
      this[`${target}Targets`]?.forEach(element => element.disabled = true);
    });
  }

  getSelectedOption() {
    return this.hasSelectorTarget ? this.selectorTarget.querySelector('input[type="radio"]:checked')?.value : 'assets';
  }

  show(element, shouldShow, inline = false) {
    if (element) {
      element.style.display = shouldShow ? (inline ? 'inline-block' : 'block') : 'none';
    }
  }
}
