import { Controller } from "@hotwired/stimulus"
import { debounce } from "lodash-es"

export default class extends Controller {
  static values = {
    min: Number,
    max: Number,
    warning: String,
  }

  static targets = ["input", "warning"]

  initialize() {
    this.warnOutOfRange = debounce(this.warnOutOfRange, 200).bind(this)
  }

  connect() {
    this.warnOutOfRange()
  }

  warnOutOfRange() {
    if (this.hasInputTarget && this.inputTarget.value.length > 0) {
      this.hideErrors()
      this.setWarningMessage()
      this.showWarnings()
    }
  }

  setWarningMessage() {
    let targetVal = this.inputTarget.value
    let outOfRange = false
    if (this.hasMinValue) {
      outOfRange = targetVal < this.minValue
    }
    if (this.hasMaxValue && !outOfRange) {
      outOfRange = targetVal > this.maxValue
    }

    if (outOfRange) {
      this.warningValue = this.outOfRangeMessage()
    } else {
      this.warningValue = undefined
    }
  }

  showWarnings() {
    if (this.hasWarningValue) {
      this.warningTarget.innerHTML = ""

      let warningEl
      warningEl = document.createElement("div")
      warningEl.classList.add("warning")

      warningEl.innerHTML = this.warningValue

      this.warningTarget.appendChild(warningEl)
    } else {
      this.warningTarget.innerHTML = ""
    }
  }

  hideErrors() {
    this.inputTarget.parentNode.classList.remove("error")
    this.inputTarget.parentNode.querySelectorAll("small.error").forEach(function (error) {
      error.remove()
    })
  }

  outOfRangeMessage() {
    if (this.hasMinValue && this.hasMaxValue) {
      return `Expected value between ${this.minValue} and ${this.maxValue}`
    }
    if (this.hasMinValue) {
      return `Expected minimum value ${this.minValue}`
    }
    if (this.hasMaxValue) {
      return `Expected maximum value ${this.maxValue}`
    }
  }
}
