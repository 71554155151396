import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['detail', 'contractInput', 'sendToContractorInput']

  connect() {
    // initialise ui state on form with errors
    if (this.hasContractInputTarget && this.contractInputTarget.value.length > 0) {
      this.setContractInputStatus()
    } else if (this.hasSendToContractorInputTarget && this.sendToContractorInputTarget.checked) {
      this.setSendToContractorInputStatus()
    }
  }

  onContractorChange(e) {
    this.setSendToContractorInputStatus()
  }

  setSendToContractorInputStatus() {
    if (!this.hasSendToContractorInputTarget) {
      console.error('unable to set sendToContractor status')
      return
    }
    const detailsElem = this.sendToContractorInputTarget.closest('details')
    if (this.sendToContractorInputTarget.checked) {
      this.disableOtherOptions(detailsElem)
      detailsElem.setAttribute('open', true)
    } else {
      this.enableOtherOptions(detailsElem)
    }
  }

  onContractChange(e) {
    this.setContractInputStatus()
  }

  setContractInputStatus() {
    if (!this.hasContractInputTarget) {
      console.error('unable to set contractInput status')
      return
    }
    const detailsElem = this.contractInputTarget.closest('details')
    if (this.contractInputTarget.value.length > 0) {
      this.disableOtherOptions(detailsElem)
      detailsElem.setAttribute('open', true)
    } else {
      this.enableOtherOptions(detailsElem)
    }
  }

  disableOtherOptions(detailsElem) {
    this.detailTargets.forEach((target) => {
      if (target != detailsElem) {
        target.classList.add('disabled')
        target.querySelectorAll('input').forEach((input) => {
          input.disabled = true
        })
        target.querySelectorAll('.button').forEach((button) => {
          button.classList.add('disabled')
        })
      }
    })
  }

  enableOtherOptions(detailsElem) {
    this.detailTargets.forEach((target) => {
      if (target != detailsElem) {
        target.classList.remove('disabled')
        target.querySelectorAll('input').forEach((input) => {
          input.disabled = false
        })
        target.querySelectorAll('.button').forEach((button) => {
          button.classList.remove('disabled')
        })
      }
    })
  }

}