import { Controller } from '@hotwired/stimulus';
import { DirectUpload } from '@rails/activestorage';
import Croppie from 'croppie';

/*
 * A UI for uploading a photograph with an adjustable crop for display in a circle
 */
export default class extends Controller {

  static targets = ['current', 'edit', 'fileInputLabel', 'fileInput', 'blob', 'croppie', 'uploadButton', 'validationMessage', 'form'];

  connect() {
    this.image_url = false
    this.uploading = false

    this.displayCurrent()
    this.disableUpload()
    this.initCroppie()
  }

  disconnect() {
    if (this.croppie) {
      this.croppie.destroy()
    }
  }

  displayCurrent() {
    this.editTarget.classList.add('hide')
    this.currentTarget.classList.remove('hide')
    document.getElementById('edit-user-form').classList.remove('hide')
  }

  displayEdit() {
    this.editTarget.classList.remove('hide')
    this.currentTarget.classList.add('hide')
    document.getElementById('edit-user-form').classList.add('hide')
  }


  initCroppie() {
    if (this.croppie) { return }

    this.croppie = new Croppie(this.croppieTarget, {
      enableExif: true,
      enableOrientation: true,
      viewport: {
        width: 290,
        height: 290,
        type: 'circle'
      },
      boundary: {
        width: '100%',
        height: 300
      }
    })
  }

  resize() {
    if (!this.croppie) { return }
    if (!this.image_url) { return }

    this.croppie.bind({ url: this.image_url, orientation: 1 }).then(() => {
      this.croppie.setZoom(0)
    })
  }

  openFileBrowser() {
    this.fileInputLabelTarget.click()
  }

  rotate() {
    if (!this.croppie) { return }

    this.croppie.rotate(90)
  }

  disableUpload() {
    this.uploadButtonTarget.setAttribute('disabled', 'disabled')
  }

  beginUpload() {
    this.disableUpload()
    this.uploadButtonTarget.classList.add('in-progress')
  }

  enableUpload() {
    this.uploadButtonTarget.classList.remove('in-progress')
    this.uploadButtonTarget.removeAttribute('disabled')
  }

  process() {
    if (this.fileInputTarget.files && this.fileInputTarget.files[0]) {
      let reader = new FileReader();

      reader.onload = (e) => {
        this.displayEdit()

        // bind croppie
        this.croppie.bind({ url: e.target.result }).then(() => {
          this.image_url = e.target.result
          this.resetValidationMessage()
          this.enableUpload()

          this.croppie.setZoom(0)
        });
      }

      reader.readAsDataURL(this.fileInputTarget.files[0]);
    }
  }

  cancel() {
    this.displayCurrent()
  }

  upload(e) {
    if (this.uploading) {
      return
    }
    this.uploading = true

    this.resetValidationMessage()

    if (!this.image_url) {
      this.showValidationMessage("Please select a photo first")
    } else {
      this.croppie.result({
        type: 'blob',
        format: 'jpeg',
        quality: 0.9,
        size: { width: 400, height: 400 },
        circle: false // we'll crop it with CSS
      }).then((blob) => {
        blob.name = this.fileInputTarget.files[0].name

        this.beginUpload()

        const uploader = new DirectUpload(blob, this.fileInputTarget.dataset.directUploadUrl);
        uploader.create((error, uploaded_blob) => {
          if (error) {
            this.showValidationMessage("Sorry, an error occurred when uploading. Please try again")
            this.enableUpload()
          } else {
            this.blobTarget.value = uploaded_blob.signed_id;
            Rails.fire(this.formTarget, 'submit');
          }

          this.uploading = false;
        })
      })
    }
  }

  resetValidationMessage() {
    this.validationMessageTarget.classList.add('hide')
  }

  showValidationMessage(msg) {
    this.validationMessageTarget.classList.remove('hide')
    this.validationMessageTarget.innerHTML = msg
  }

  uploadComplete() {
    window.location.reload()
  }
}
