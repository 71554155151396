// This controller holds the logic for the estimate form
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["promo", "estimateForm", "completedOnMode", "datePicker", "saveDateButton", "taskNeverDone"]

  connect() {
    this.originalButtonText = this.saveDateButtonTarget.value;
  }

  showEstimateForm() {
    this.promoTargets.forEach(promo => promo.classList.add('hide'));
    this.estimateFormTargets.forEach(form => form.classList.remove('hide'));
  }

  toggleTaskCompletionMode() {
    // Check if calendar should be disabled
    const shouldDisable = this.taskNeverDoneTarget.checked;
    const mode = shouldDisable ? 'asap' : this.completedOnModeTarget.dataset.mode;
    this.completedOnModeTarget.value = mode;

    // Update all inputs and the calendar icon
    this.updateInputsAndIconState(shouldDisable);

    // Update button text based on the mode
    this.saveDateButtonTarget.value = mode === 'asap' ? "Schedule ASAP" : this.originalButtonText;
  }

  updateInputsAndIconState(shouldDisable) {
    // Toggle disable the rendered datepicker
    const input = this.datePickerTarget.querySelector('input.datepicker:not([type="hidden"])');
    input.disabled = shouldDisable;

    // Add disabled class to icon
    const calendarIcon = this.datePickerTarget.querySelector('.fa-calendar');
    if (calendarIcon) {
      calendarIcon.classList.toggle('disabled', shouldDisable);
    }
  }

}
