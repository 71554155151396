import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "text",
    "button"
  ]

  updateButtonStatus(event) {
    this.buttonTarget.disabled = (this.textTarget.editor.getDocument().toString().trim() == "")
  }
}
