import { Controller } from "@hotwired/stimulus"
import { get } from 'lodash-es'

export default class extends Controller {

  static values = {
    gradePriority: String
  }
  static outlets = ['asset-register--current-condition']

  connect() {
    if (this.hasAssetRegisterCurrentConditionOutlet) {
      this.assetRegisterCurrentConditionOutlets.forEach(outlet => outlet.synchroniseCurrentValue(this.gradePriorityValue))
    }
  }

  synchroniseCurrentValue(parentGradePriorityValue) {
    if (this.hasGradePriorityValue && this.gradePriorityValue != parentGradePriorityValue) {
      this.gradePriorityValue = parentGradePriorityValue
    }
  }

  gradePriorityValueChanged(value, previous) {
    const scoreEl = this.element.querySelector('.score')
    const suffixEl = this.element.querySelector('.suffix')

    const oldGrade = get(previous.split(''), '[0]', null)
    let grade, suffix

    const newVals = value.split('')
    grade = get(newVals, '[0]', null)
    suffix = get(newVals, '[1]', null)

    if (grade && scoreEl) {
      scoreEl.textContent = grade
    }

    if (suffixEl) {
      if (suffix) {
        suffixEl.textContent = 'x'
      } else {
        suffixEl.textContent = ''
      }
    }

    if (oldGrade) {
      this.element.classList.remove(oldGrade.toLowerCase())
    }
    this.element.classList.add(grade.toLowerCase())
    if (suffix) {
      this.element.classList.add('x')
    } else {
      this.element.classList.remove('x')
    }
  }

}