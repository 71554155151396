import { Controller } from "@hotwired/stimulus"
import { filter, get } from 'lodash-es'

export default class extends Controller {
  static targets = ['entry']

  mouseOver(event) {
    const hoverClass = this.hoverClass(event.target)
    const targetEntries = this.eventTargets(event)
    targetEntries.forEach((el) => { el.classList.add(hoverClass) })
    event.target.classList.add(hoverClass)
  }

  mouseOut(event) {
    this.eventTargets(event).forEach(function (el) { el.classList.remove('hover-plus', 'hover-minus') })
    event.target.classList.remove('hover-plus', 'hover-minus')
  }

  eventTargets(event) {
    const grade = event.target.dataset.grade
    const priority = event.target.dataset.priority

    if (grade && !priority) {
      // hover grade (column)
      return filter(this.entryTargets, (el) => { return el.dataset.grade == grade })
    } else if (priority && !grade) {
      // hover priority (row)
      return filter(this.entryTargets, (el) => { return el.dataset.priority == priority })
    }

    return []
  }

  hoverClass(targetElem) {
    if (get(targetElem, 'dataset.selected', 'false') === 'true') {
      return 'hover-minus'
    } else {
      return 'hover-plus'
    }
  }

}