import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  toggle(event) {
    event.preventDefault()

    const nodeParent = event.target.closest('li')
    nodeParent.querySelectorAll(':scope > .nested').forEach((branch) => { branch.classList.toggle('expanded') })
    event.target.classList.toggle('expanded')
  }

}