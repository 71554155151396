import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['otherOptions', 'enableAssets', 'selector'];

  connect() {
    this.updateFormBasedOnState();
  }

  select(e) {
    this.updateFormBasedOnState();
  }

  updateFormBasedOnState() {
    if (!this.enableAssetsChecked() || this.selectAllChecked()) {
      this.updateFormSections();
      this.hideOtherOptions();
    } else {
      this.updateFormSections();
      this.showOtherOptions();
    }
  }

  showOtherOptions() {
    this.show(this.otherOptionsTarget, true);
  }

  hideOtherOptions() {
    this.show(this.otherOptionsTarget, false);
  }

  updateFormSections() {
    const event = new CustomEvent('helpdeskCategories:change');
    document.dispatchEvent(event);
  }

  // Getters for child controllers
  enableAssetsChecked() {
    return this.enableAssetsTarget.checked;
  }

  selectAllChecked() {
    return (this.enableAssetsChecked() && this.selectorTarget.checked);
  }

  show(element, shouldShow) {
    element.style.display = shouldShow ? 'block' : 'none';
  }
}
