import { Controller } from '@hotwired/stimulus'
import { get } from 'lodash-es'

export default class extends Controller {
  static targets = ['child']

  refresh(event) {
    const srcTarget = get(event, 'detail')
    if (!srcTarget) {
      return
    }

    if (this.hasChildTarget && this.childTargets.includes(srcTarget)) {
      event.preventDefault()
      event.stopImmediatePropagation()
      const src = this.element.getAttribute('src')
      if (src.length > 0) {
        this.element.removeAttribute('src')
        this.element.setAttribute('src', src)
      }
    }
  }
}
