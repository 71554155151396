import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["stars", "comment", "cancel"];

  initialize() {
    this.changeHandler = this.changeHandler.bind(this);
    this.cancel = this.cancel.bind(this);
  }

  connect() {
    this.starsTarget.addEventListener('click', this.changeHandler);
    this.changeHandler();
  }

  changeHandler(_event) {
    if (this.starsTarget.querySelectorAll('input[type="radio"]:checked').length !== 0) {
      this.commentTarget.classList.remove('hide');
      this.cancelTarget.classList.remove('hide');
    } else {
      this.cancel();
    }
  }

  cancel(event = null) {
    if (event) {
      event.preventDefault();
    }
    this.starsTarget.querySelectorAll('input[type="radio"]').forEach((el) => el.checked = false);
    this.cancelTarget.classList.add('hide');
    this.commentTarget.querySelector('textarea').value = '';
    this.commentTarget.classList.add('hide');
  }

}
