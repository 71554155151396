import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["checkbox", "selectedUsers"]

  connect() {
    this.updateButtonState()

    document.addEventListener("turbo:frame-load", () => {
      this.updateButtonState();
    })
  }

  toggleAll(event) {
    const isChecked = event.target.checked
    this.checkboxTargets.forEach(checkbox => {
      checkbox.checked = isChecked
      this.updateSelectUserButton(checkbox)
    })
    this.updateButtonState()
  }

  updateSelectedCount() {
    const selectedUsers = this.checkboxTargets.filter(checkbox => checkbox.checked).length;
    const userText = selectedUsers === 1 ? 'user' : 'users';
    
    this.selectedUsersTarget.textContent = `${selectedUsers} ${userText} selected`;
  }  

  updateButtonState(e) {
    if (!this.hasCheckboxTarget) {
      return
    }

    const checkedBoxes = this.checkboxTargets.filter(checkbox => checkbox.checked)
    const submitButton = this.element.querySelector('input[type="submit"]')
    submitButton.disabled = checkedBoxes.length === 0
    
    if (this.hasSelectedUsersTarget) {
      this.updateSelectedCount();
    }

    // prevents updateButtonState call on connect erroring when no event is passed
    if (e) {
      this.updateSelectUserButton(e.target)
    }
  }

  submit(event) {
    const checkedBoxes = this.checkboxTargets.filter(checkbox => checkbox.checked)
    if (checkedBoxes.length === 0) {
      event.preventDefault()
      alert("Please select at least one user to unassign.")
    }
  }

  updateSelectUserButton(input) {
    const label = input.parentElement
    const button = label.querySelector(".select-assigned-user")

    if (input.checked) {
      button.textContent = "Unselect User"
      button.classList.add("selected")
    } else {
      button.textContent = "Select User"
      button.classList.remove("selected")
    }
  }
}