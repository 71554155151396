import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['locationInput', 'otherLocationInput', 'zoneInput'];

  connect() {
    this.updateOtherLocationInputVisibility({ target: this.locationInputTarget });
  }

  show(target, show = true) {
    if (show) {
      target.classList.remove('hide');
    } else {
      target.classList.add('hide');
    }
  }

  // If the Other Location option has been selected
  updateOtherLocationInputVisibility(e) {
    const hasSelectedBlank = e?.target?.value == ""
    const hasSelectedOther = (e?.target?.value == "" && e?.target?.selectedIndex != 0);

    this.show(this.otherLocationInputTarget.closest('div.wrapper'), hasSelectedOther);

    if (this.hasZoneInputTarget) {
      this.show(this.zoneInputTarget.closest('div.wrapper'), !hasSelectedBlank);
    }
  }
}
